import { ComponentType } from "@angular/cdk/portal";
import { Observable, Subject, fromEvent, take } from "rxjs";
import { nanoid } from "nanoid";

export interface ModalOptions {
    data: any;
}

export interface AbstractModalComponent {
    close(): void;
}

export class ModalRef<T> {

    readonly id: string;
    readonly content: ComponentType<AbstractModalComponent>;
    readonly options: ModalOptions;

    public onOpen$: Subject<void> = new Subject<void>();
    public onClose$: Subject<any> = new Subject<any>();

    constructor(content: ComponentType<AbstractModalComponent>, options: ModalOptions = { data: {} }) {
        this.id = `modal-${nanoid(12)}`;
        this.content = content;
        this.options = options;
    }

    public open(): void {
        this.onOpen$.next();
        this._listenToOnCloseEvent();
    }

    public close(data?: any): void {
        this.onClose$.next(data);
    }

    get nativeElement(): HTMLDialogElement | null {
        return document.getElementById(this.id) as HTMLDialogElement | null;
    }

    private _listenToOnCloseEvent(): void {
        const nativeElement: HTMLDialogElement | null = this.nativeElement;
        if (!nativeElement) return;
        const source: Observable<Event> = fromEvent(nativeElement, "close");
        source.pipe(take(1)).subscribe((event: Event) => {
            this.onClose$.next({});
        });
    }


}