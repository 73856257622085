import { HttpInterceptorFn } from '@angular/common/http';
import { Inject, inject, isDevMode } from '@angular/core';
import { SupabaseService } from '../../services/supabase/supabase.service';
import { switchMap, take } from 'rxjs';
import { environment } from '../../../environments/environment';

export const authorizationInterceptor: HttpInterceptorFn = (req, next) => {

  const supabase = inject(SupabaseService);

  if (!req.url.startsWith(environment.chatCompletionEndpoint)) {
    // If the request is not to the API endpoint, don't modify it
    return next(req);
  }

  return supabase.accessToken$.pipe(
    take(1), // Take only the first value emitted by the accessToken observable
    switchMap(accessToken => {
      if (accessToken) {
        // Clone the request and add the Authorization header
        const authReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${accessToken}`)
        });
        return next(authReq);
      } else {
        // If there's no access token, just forward the original request
        return next(req);
      }
    })
  );
};
