import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { SupabaseService } from '../../../services/supabase/supabase.service';
import { NgxTurnstileModule } from 'ngx-turnstile';
import { environment } from '../../../../environments/environment';
import { FadeInAnimation } from '../../../animations';
import { AuthResponse } from '@supabase/supabase-js';
import { NotificationsService } from '../../../services/notifications/notifications.service';

@Component({
  selector: 'sign-in',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxTurnstileModule
  ],
  templateUrl: './sign-in.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeInAnimation]
})
export class SignInComponent implements AfterViewInit {

  public sitekey = environment.turnstileSitekey;
  public turnstileToken: string | null = window.location.origin.includes('localhost') ? 'test' : null;

  constructor(private supabase: SupabaseService, private notifications: NotificationsService, private router: Router) {

  }

  ngAfterViewInit(): void {
    this.signInForm.updateValueAndValidity();

    this.supabase.user$.subscribe(user => {
      if (user && !user.is_anonymous) {
        this.router.navigate(['/']);
      }
    })
  }

  public signInForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  public signIn(): void {
    if (!this.turnstileToken) {
      return;
    }

    const email = this.signInForm.get('email')?.value;
    const password = this.signInForm.get('password')?.value;

    this.supabase.supabase.auth.signInWithPassword({
      email: email,
      password: password,
      options: {
        captchaToken: this.turnstileToken,
      }
    }).then((response: AuthResponse) => {
      if (response.error) {
        this.notifications.error('Error', response.error.message);
      } else {
        this.notifications.success('Success', `Succesfully signed in as ${email}`);
        this.router.navigate(['/new']);
      }

    }).catch((error) => {
      console.error('Error signing in:', error);
    }
    )
  }

  public signInWithGoogle(): void {
    this.supabase.signInWithGoogle()
  }

  public setTurnstileToken(turnstileToken: string | null): void {
    this.turnstileToken = turnstileToken;
  }

}
