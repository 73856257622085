<!--docxjs library predefined styles-->
<style>
    .docx-wrapper {
        background: gray;
        padding: 30px;
        padding-bottom: 0px;
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    .docx-wrapper>section.docx {
        background: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        margin-bottom: 30px;
    }

    .docx {
        color: black;
    }

    section.docx {
        box-sizing: border-box;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        overflow: hidden;
    }

    section.docx>article {
        margin-bottom: auto;
    }

    .docx table {
        border-collapse: collapse;
    }

    .docx table td,
    .docx table th {
        vertical-align: top;
    }

    .docx p {
        margin: 0pt;
        min-height: 1em;
    }

    .docx span {
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }

    .docx a {
        color: inherit;
        text-decoration: inherit;
    }
</style><!--docxjs document theme values-->
<style>
    .docx {
        --docx-majorHAnsi-font: Calibri Light;
        --docx-minorHAnsi-font: Calibri;
        --docx-dk1-color: #000000;
        --docx-lt1-color: #FFFFFF;
        --docx-dk2-color: #44546A;
        --docx-lt2-color: #E7E6E6;
        --docx-accent1-color: #4472C4;
        --docx-accent2-color: #ED7D31;
        --docx-accent3-color: #A5A5A5;
        --docx-accent4-color: #FFC000;
        --docx-accent5-color: #5B9BD5;
        --docx-accent6-color: #70AD47;
        --docx-hlink-color: #0563C1;
        --docx-folHlink-color: #954F72;
    }
</style><!--docxjs document styles-->
<style>
    .docx span {
        font-family: var(--docx-minorHAnsi-font);
        min-height: 12.00pt;
        font-size: 12.00pt;
    }

    p.docx_heading1 {
        margin-top: 18.00pt;
        margin-bottom: 4.00pt;
    }

    p.docx_heading1 span {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 20.00pt;
        font-size: 20.00pt;
    }

    p.docx_heading1 span {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 20.00pt;
        font-size: 20.00pt;
    }

    p.docx_heading2 {
        margin-top: 8.00pt;
        margin-bottom: 4.00pt;
    }

    p.docx_heading2 span {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 16.00pt;
        font-size: 16.00pt;
    }

    p.docx_heading2 span {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 16.00pt;
        font-size: 16.00pt;
    }

    p.docx_heading3 {
        margin-top: 8.00pt;
        margin-bottom: 4.00pt;
    }

    p.docx_heading3 span {
        color: #2F5496;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    p.docx_heading3 span {
        color: #2F5496;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    p.docx_heading4 {
        margin-top: 4.00pt;
        margin-bottom: 2.00pt;
    }

    p.docx_heading4 span {
        font-style: italic;
        color: #2F5496;
    }

    p.docx_heading4 span {
        font-style: italic;
        color: #2F5496;
    }

    p.docx_heading5 {
        margin-top: 4.00pt;
        margin-bottom: 2.00pt;
    }

    p.docx_heading5 span {
        color: #2F5496;
    }

    p.docx_heading5 span {
        color: #2F5496;
    }

    p.docx_heading6 {
        margin-top: 2.00pt;
    }

    p.docx_heading6 span {
        font-style: italic;
        color: #595959;
    }

    p.docx_heading6 span {
        font-style: italic;
        color: #595959;
    }

    p.docx_heading7 {
        margin-top: 2.00pt;
    }

    p.docx_heading7 span {
        color: #595959;
    }

    p.docx_heading7 span {
        color: #595959;
    }

    p.docx_heading8 {}

    p.docx_heading8 span {
        font-style: italic;
        color: #272727;
    }

    p.docx_heading8 span {
        font-style: italic;
        color: #272727;
    }

    p.docx_heading9 {}

    p.docx_heading9 span {
        color: #272727;
    }

    p.docx_heading9 span {
        color: #272727;
    }

    .docx table,
    table.docx_tablenormal td {
        padding-top: 0.00pt;
        padding-left: 5.40pt;
        padding-bottom: 0.00pt;
        padding-right: 5.40pt;
    }

    span.docx_heading1char {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 20.00pt;
        font-size: 20.00pt;
    }

    span.docx_heading1char p {
        margin-top: 18.00pt;
        margin-bottom: 4.00pt;
    }

    span.docx_heading1char {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 20.00pt;
        font-size: 20.00pt;
    }

    span.docx_heading2char {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 16.00pt;
        font-size: 16.00pt;
    }

    span.docx_heading2char p {
        margin-top: 8.00pt;
        margin-bottom: 4.00pt;
    }

    span.docx_heading2char {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 16.00pt;
        font-size: 16.00pt;
    }

    span.docx_heading3char {
        color: #2F5496;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    span.docx_heading3char p {
        margin-top: 8.00pt;
        margin-bottom: 4.00pt;
    }

    span.docx_heading3char {
        color: #2F5496;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    span.docx_heading4char {
        font-style: italic;
        color: #2F5496;
    }

    span.docx_heading4char p {
        margin-top: 4.00pt;
        margin-bottom: 2.00pt;
    }

    span.docx_heading4char {
        font-style: italic;
        color: #2F5496;
    }

    span.docx_heading5char {
        color: #2F5496;
    }

    span.docx_heading5char p {
        margin-top: 4.00pt;
        margin-bottom: 2.00pt;
    }

    span.docx_heading5char {
        color: #2F5496;
    }

    span.docx_heading6char {
        font-style: italic;
        color: #595959;
    }

    span.docx_heading6char p {
        margin-top: 2.00pt;
    }

    span.docx_heading6char {
        font-style: italic;
        color: #595959;
    }

    span.docx_heading7char {
        color: #595959;
    }

    span.docx_heading7char p {
        margin-top: 2.00pt;
    }

    span.docx_heading7char {
        color: #595959;
    }

    span.docx_heading8char {
        font-style: italic;
        color: #272727;
    }

    span.docx_heading8char p {}

    span.docx_heading8char {
        font-style: italic;
        color: #272727;
    }

    span.docx_heading9char {
        color: #272727;
    }

    span.docx_heading9char p {}

    span.docx_heading9char {
        color: #272727;
    }

    p.docx_title {
        margin-bottom: 4.00pt;
    }

    p.docx_title span {
        font-family: var(--docx-majorHAnsi-font);
        min-height: 28.00pt;
        font-size: 28.00pt;
    }

    p.docx_title span {
        font-family: var(--docx-majorHAnsi-font);
        min-height: 28.00pt;
        font-size: 28.00pt;
    }

    span.docx_titlechar {
        font-family: var(--docx-majorHAnsi-font);
        min-height: 28.00pt;
        font-size: 28.00pt;
    }

    span.docx_titlechar p {
        margin-bottom: 4.00pt;
    }

    span.docx_titlechar {
        font-family: var(--docx-majorHAnsi-font);
        min-height: 28.00pt;
        font-size: 28.00pt;
    }

    p.docx_subtitle {
        margin-bottom: 8.00pt;
    }

    p.docx_subtitle span {
        color: #595959;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    p.docx_subtitle span {
        color: #595959;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    span.docx_subtitlechar {
        color: #595959;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    span.docx_subtitlechar p {
        margin-bottom: 8.00pt;
    }

    span.docx_subtitlechar {
        color: #595959;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    p.docx_quote {
        margin-top: 8.00pt;
        margin-bottom: 8.00pt;
        text-align: center;
    }

    p.docx_quote span {
        font-style: italic;
        color: #404040;
    }

    p.docx_quote span {
        font-style: italic;
        color: #404040;
    }

    span.docx_quotechar {
        font-style: italic;
        color: #404040;
    }

    span.docx_quotechar p {
        margin-top: 8.00pt;
        margin-bottom: 8.00pt;
        text-align: center;
    }

    span.docx_quotechar {
        font-style: italic;
        color: #404040;
    }

    p.docx_listparagraph {
        margin-left: 36.00pt;
    }

    span.docx_intenseemphasis {
        font-style: italic;
        color: #2F5496;
    }

    p.docx_intensequote {
        border-top: 0.50pt solid #2F5496;
        border-bottom: 0.50pt solid #2F5496;
        margin-top: 18.00pt;
        margin-bottom: 18.00pt;
        margin-left: 43.20pt;
        margin-right: 43.20pt;
        text-align: center;
    }

    p.docx_intensequote span {
        font-style: italic;
        color: #2F5496;
    }

    p.docx_intensequote span {
        font-style: italic;
        color: #2F5496;
    }

    span.docx_intensequotechar {
        font-style: italic;
        color: #2F5496;
    }

    span.docx_intensequotechar p {
        border-top: 0.50pt solid #2F5496;
        border-bottom: 0.50pt solid #2F5496;
        margin-top: 18.00pt;
        margin-bottom: 18.00pt;
        margin-left: 43.20pt;
        margin-right: 43.20pt;
        text-align: center;
    }

    span.docx_intensequotechar {
        font-style: italic;
        color: #2F5496;
    }

    span.docx_intensereference {
        font-weight: bold;
        text-transform: lowercase;
        color: #2F5496;
    }

    span.docx_hyperlink {
        color: #0563C1;
        text-decoration: underline;
    }

    span.docx_unresolvedmention {
        color: #605E5C;
        background-color: #E1DFDD;
    }
</style><!--docxjs document numbering styles-->
<style>
    p.docx-num-1-0:before {
        content: "" counter(docx-num-1-0, decimal)".\9";
        counter-increment: docx-num-1-0;
    }

    p.docx-num-1-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-1-0 {
        counter-reset: docx-num-1-1;
    }

    p.docx-num-1-1:before {
        content: "" counter(docx-num-1-1, lower-alpha)".\9";
        counter-increment: docx-num-1-1;
    }

    p.docx-num-1-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-1-1 {
        counter-reset: docx-num-1-2;
    }

    p.docx-num-1-2:before {
        content: "" counter(docx-num-1-2, lower-roman)".\9";
        counter-increment: docx-num-1-2;
    }

    p.docx-num-1-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-1-2 {
        counter-reset: docx-num-1-3;
    }

    p.docx-num-1-3:before {
        content: "" counter(docx-num-1-3, decimal)".\9";
        counter-increment: docx-num-1-3;
    }

    p.docx-num-1-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-1-3 {
        counter-reset: docx-num-1-4;
    }

    p.docx-num-1-4:before {
        content: "" counter(docx-num-1-4, lower-alpha)".\9";
        counter-increment: docx-num-1-4;
    }

    p.docx-num-1-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-1-4 {
        counter-reset: docx-num-1-5;
    }

    p.docx-num-1-5:before {
        content: "" counter(docx-num-1-5, lower-roman)".\9";
        counter-increment: docx-num-1-5;
    }

    p.docx-num-1-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-1-5 {
        counter-reset: docx-num-1-6;
    }

    p.docx-num-1-6:before {
        content: "" counter(docx-num-1-6, decimal)".\9";
        counter-increment: docx-num-1-6;
    }

    p.docx-num-1-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-1-6 {
        counter-reset: docx-num-1-7;
    }

    p.docx-num-1-7:before {
        content: "" counter(docx-num-1-7, lower-alpha)".\9";
        counter-increment: docx-num-1-7;
    }

    p.docx-num-1-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-1-7 {
        counter-reset: docx-num-1-8;
    }

    p.docx-num-1-8:before {
        content: "" counter(docx-num-1-8, lower-roman)".\9";
        counter-increment: docx-num-1-8;
    }

    p.docx-num-1-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-2-0:before {
        content: "" counter(docx-num-2-0, decimal)".\9";
        counter-increment: docx-num-2-0;
    }

    p.docx-num-2-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 18.00pt;
    }

    p.docx-num-2-0 {
        counter-reset: docx-num-2-1;
    }

    p.docx-num-2-1:before {
        content: "" counter(docx-num-2-1, lower-alpha)".\9";
        counter-increment: docx-num-2-1;
    }

    p.docx-num-2-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 54.00pt;
    }

    p.docx-num-2-1 {
        counter-reset: docx-num-2-2;
    }

    p.docx-num-2-2:before {
        content: "" counter(docx-num-2-2, lower-roman)".\9";
        counter-increment: docx-num-2-2;
    }

    p.docx-num-2-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 90.00pt;
    }

    p.docx-num-2-2 {
        counter-reset: docx-num-2-3;
    }

    p.docx-num-2-3:before {
        content: "" counter(docx-num-2-3, decimal)".\9";
        counter-increment: docx-num-2-3;
    }

    p.docx-num-2-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 126.00pt;
    }

    p.docx-num-2-3 {
        counter-reset: docx-num-2-4;
    }

    p.docx-num-2-4:before {
        content: "" counter(docx-num-2-4, lower-alpha)".\9";
        counter-increment: docx-num-2-4;
    }

    p.docx-num-2-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 162.00pt;
    }

    p.docx-num-2-4 {
        counter-reset: docx-num-2-5;
    }

    p.docx-num-2-5:before {
        content: "" counter(docx-num-2-5, lower-roman)".\9";
        counter-increment: docx-num-2-5;
    }

    p.docx-num-2-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 198.00pt;
    }

    p.docx-num-2-5 {
        counter-reset: docx-num-2-6;
    }

    p.docx-num-2-6:before {
        content: "" counter(docx-num-2-6, decimal)".\9";
        counter-increment: docx-num-2-6;
    }

    p.docx-num-2-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 234.00pt;
    }

    p.docx-num-2-6 {
        counter-reset: docx-num-2-7;
    }

    p.docx-num-2-7:before {
        content: "" counter(docx-num-2-7, lower-alpha)".\9";
        counter-increment: docx-num-2-7;
    }

    p.docx-num-2-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 270.00pt;
    }

    p.docx-num-2-7 {
        counter-reset: docx-num-2-8;
    }

    p.docx-num-2-8:before {
        content: "" counter(docx-num-2-8, lower-roman)".\9";
        counter-increment: docx-num-2-8;
    }

    p.docx-num-2-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 306.00pt;
    }

    p.docx-num-3-0:before {
        content: "-\9";
        counter-increment: docx-num-3-0;
        font-family: Calibri;
    }

    p.docx-num-3-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-3-0 {
        counter-reset: docx-num-3-1;
    }

    p.docx-num-3-1:before {
        content: "o\9";
        counter-increment: docx-num-3-1;
        font-family: Courier New;
    }

    p.docx-num-3-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-3-1 {
        counter-reset: docx-num-3-2;
    }

    p.docx-num-3-2:before {
        content: "\9";
        counter-increment: docx-num-3-2;
        font-family: Wingdings;
    }

    p.docx-num-3-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-3-2 {
        counter-reset: docx-num-3-3;
    }

    p.docx-num-3-3:before {
        content: "\9";
        counter-increment: docx-num-3-3;
        font-family: Symbol;
    }

    p.docx-num-3-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-3-3 {
        counter-reset: docx-num-3-4;
    }

    p.docx-num-3-4:before {
        content: "o\9";
        counter-increment: docx-num-3-4;
        font-family: Courier New;
    }

    p.docx-num-3-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-3-4 {
        counter-reset: docx-num-3-5;
    }

    p.docx-num-3-5:before {
        content: "\9";
        counter-increment: docx-num-3-5;
        font-family: Wingdings;
    }

    p.docx-num-3-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-3-5 {
        counter-reset: docx-num-3-6;
    }

    p.docx-num-3-6:before {
        content: "\9";
        counter-increment: docx-num-3-6;
        font-family: Symbol;
    }

    p.docx-num-3-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-3-6 {
        counter-reset: docx-num-3-7;
    }

    p.docx-num-3-7:before {
        content: "o\9";
        counter-increment: docx-num-3-7;
        font-family: Courier New;
    }

    p.docx-num-3-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-3-7 {
        counter-reset: docx-num-3-8;
    }

    p.docx-num-3-8:before {
        content: "\9";
        counter-increment: docx-num-3-8;
        font-family: Wingdings;
    }

    p.docx-num-3-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 324.00pt;
    }

    .docx-wrapper {
        counter-reset: docx-num-1-0 docx-num-2-0 docx-num-3-0;
    }
</style>
<div class="docx-wrapper">
    <section class="docx" style="padding: 72pt; width: 612pt; min-height: 792pt;">
        <article>
            <p><span style="font-weight: bold; min-height: 16pt; font-size: 16pt;">Privacy Policy</span></p>
            <p></p>
            <p><span>We at</span><span> Polybius</span><span>, LLC (together with our affiliates,
                    “</span><span>Polybius</span><span>”, “we”, “our” or “us”) respect your privacy and are strongly
                    committed to keeping secure any information we obtain from you or about you. This Privacy Policy
                    describes our practices with respect to Personal Information we collect from or about you when you
                    use our website, applications, and services (collectively, “Services”). </span></p>
            <p></p>
            <p class="docx_listparagraph docx-num-2-0"><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Personal information we collect
                </span></p>
            <p></p>
            <p><span>We collect personal information relating to you (“Personal Information”) as follows: </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Personal Information You Provide:</span><span> We collect Personal
                    Information if you create an account to use our Services or communicate with us as follows: </span>
            </p>
            <p></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Account
                    Information:</span><span> When you create an account with us, we will collect information associated
                    with your account, including your name, contact information, account credentials, payment card
                    information, and transaction history, (collectively, “Account Information”). </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">User Content:</span><span> When
                    you use our Services, we collect Personal Information that is included in the input, file uploads,
                    or feedback that you provide to our Services (“Content”). </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Communication
                    Information:</span><span> If you communicate with us, we collect your name, contact information, and
                    the contents of any messages you send (“Communication Information”). </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Social Media
                    Information:</span><span> We have pages on social media sites like Instagram, Facebook, Medium,
                    Twitter, YouTube and LinkedIn. When you interact with our social media pages, we will collect
                    Personal Information that you elect to provide to us, such as your contact details (collectively,
                    “Social Information”). In addition, the companies that host our social media pages may provide us
                    with aggregate information and analytics about our social media activity. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Other Information You
                    Provide:</span><span> We collect other information that you may provide to us, such as when you
                    participate in our events or surveys or provide us with information to establish your identity
                    (collectively, “Other Information You Provide” </span></p>
            <p style="margin-left: 18pt;"></p>
            <p><span style="font-weight: bold;">Personal Information We Receive Automatically From Your Use of the
                    Services:</span><span> When you visit, use, or interact with the Services, we receive the following
                    information about your visit, use, or interactions (“Technical Information”): </span></p>
            <p></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Log Data:</span><span>
                    Information that your browser or device automatically sends when you use our Services. Log data
                    includes your Internet Protocol address, browser type and settings, the date and time of your
                    request, and how you interact with our Services. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Usage Data:</span><span> We may
                    automatically collect information about your use of the Services, such as the types of content that
                    you view or engage with, the features you use and the actions you take, as well as your time zone,
                    country, the dates and times of access, user agent and version, type of computer or mobile device,
                    and your computer connection. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Device
                    Information:</span><span> Includes name of the device, operating system, device identifiers, and
                    browser you are using. Information collected may depend on the type of device you use and its
                    settings. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Cookies:</span><span> We use
                    cookies to operate and administer our Services, and improve your experience. A “cookie” is a piece
                    of information sent to your browser by a website you visit. You can set your browser to accept all
                    cookies, to reject all cookies, or to notify you whenever a cookie is offered so that you can decide
                    each time whether to accept it. However, refusing a cookie may in some cases preclude you from
                    using, or negatively affect the display or function of, a website or certain areas or features of a
                    website. For more details on cookies, please visit All About Cookies. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Analytics:</span><span> We may
                    use a variety of online analytics products that use cookies to help us analyze how users use our
                    Services and enhance your experience when you use the Services. </span></p>
            <p class="docx_listparagraph"></p>
            <p class="docx_listparagraph docx-num-2-0"><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">How we use personal information
                </span></p>
            <p class="docx_listparagraph" style="margin-left: 18pt;"></p>
            <p class="docx_listparagraph" style="margin-left: 18pt;"><span>We may use Personal Information for the
                    following purposes: </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>To provide, administer, maintain and/or analyze the
                    Services; </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>To improve our Services and conduct research; </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>To communicate with you; including to send you information
                    about our Services and events; To develop new programs and services; </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>To prevent fraud, criminal activity, or misuses of our
                    Services, and to protect the security of our IT systems, architecture, and networks; </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>To carry out business transfers; and </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>To comply with legal obligations and legal process and to
                    protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or other third
                    parties. </span></p>
            <p class="docx_listparagraph"></p>
            <p><span style="font-weight: bold;">Aggregated or De-Identified Information.</span><span> We may aggregate
                    or de-identify Personal Information so that it may no longer be used to identify you and use such
                    information to analyze the effectiveness of our Services, to improve and add features to our
                    Services, to conduct research and for other similar purposes. In addition, from time to time, we may
                    analyze the general behavior and characteristics of users of our Services and share aggregated
                    information like general user statistics with third parties, publish such aggregated information or
                    make such aggregated information generally available. We may collect aggregated information through
                    the Services, through cookies, and through other means described in this Privacy Policy. We will
                    maintain and use de-identified information in anonymous or de-identified form and we will not
                    attempt to reidentify the information, unless required by law. </span></p>
            <p></p>
            <p><span>As noted above, we may use Content you provide us to improve our Services, for example to
                    trai</span><span>n</span><span> </span><span>models</span><span>. </span></p>
            <p></p>
            <p class="docx_listparagraph docx-num-2-0"><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Disclosure of </span><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">personal </span><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">information </span></p>
            <p class="docx_listparagraph" style="margin-left: 18pt;"><span>In certain circumstances we may provide your
                    Personal Information to third parties without further notice to you, unless required by the law:
                </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Vendors and Service
                    Providers:</span><span> To assist us in meeting business operations needs and to perform certain
                    services and functions, we may provide Personal Information to vendors and service providers,
                    including providers of hosting services, customer service vendors, cloud services, email
                    communication software, web analytics services, and other information technology providers, among
                    others. Pursuant to our instructions, these parties will access, process, or store Personal
                    Information only in the course of performing their duties to us. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Business
                    Transfers:</span><span> If we are involved in strategic transactions, reorganization, bankruptcy,
                    receivership, or transition of service to another provider (collectively, a “Transaction”), your
                    Personal Information and other information may be disclosed in the diligence process with
                    counterparties and others assisting with the Transaction and transferred to a successor or affiliate
                    as part of that Transaction along with other assets. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Legal
                    Requirements:</span><span> We may share your Personal Information, including information about your
                    interaction with our Services, with government authorities, industry peers, or other third parties
                    (i) if required to do so by law or in the good faith belief that such action is necessary to comply
                    with a legal obligation, (ii) to protect and defend our rights or property, (iii) if we determine,
                    in our sole discretion, that there is a violation of our terms, policies, or the law; (iv) to detect
                    or prevent fraud or other illegal activity; (v) to protect the safety, security, and integrity of
                    our products, employees, or users, or the public, or (vi) to protect against legal liability.
                </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Affiliates:</span><span> We may
                    disclose Personal Information to our affiliates, meaning an entity that controls, is controlled by,
                    or is under common control with</span><span> Polybius</span><span>. Our affiliates may use the
                    Personal Information we share in a manner consistent with this Privacy Policy. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Business Account
                    Administrators</span><span>: When you join a </span><span>Faster </span><span>Enterprise or business
                    account, the administrators of that account may access and control your </span><span>Polybius
                </span><span>account. In addition, if you create an account using an email address belonging to your
                    employer or another organization, we may share the fact that you have a</span><span>
                    Polybius</span><span> account and certain account information, such as your email address, with your
                    employer or organization to, for example, enable you to be added to their business account. </span>
            </p>
            <p class="docx_listparagraph docx-num-3-0"><span style="font-style: italic;">Other Users and Third Parties
                    You Share Information With:</span><span> Certain features allow you to display or share information
                    with other users or third parties. For example, you may share</span><span> Faster</span><span>
                    conversations with other users via shared links</span><span>. </span><span>Be sure you trust any
                    user or third party with whom you share information. </span></p>
            <p class="docx_listparagraph"></p>
            <p class="docx_listparagraph docx-num-2-0"><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Your rights </span></p>
            <p></p>
            <p><span>Depending on location, individuals may have certain statutory rights in relation to their Personal
                    Information. For example, you may have the right to: </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>Access your Personal Information and information relating
                    to how it is processed. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>Delete your Personal Information from our records. </span>
            </p>
            <p class="docx_listparagraph docx-num-3-0"><span>Rectify or update your Personal Information. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>Transfer your Personal Information to a third party (right
                    to data portability). </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>Restrict how we process your Personal Information. </span>
            </p>
            <p class="docx_listparagraph docx-num-3-0"><span>Withdraw your consent—where we rely on consent as the legal
                    basis for processing at any time. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>Object to how we process your Personal Information. </span>
            </p>
            <p class="docx_listparagraph docx-num-3-0"><span>Lodge a complaint with your local data protection
                    authority. </span></p>
            <p class="docx_listparagraph"></p>
            <p><span>You can exercise some of these rights through your</span><span> Polybius</span><span> account. If
                    you are unable to exercise your rights through your account, please submit your request through to
                </span><span>support</span><span>&#64;</span><span>faster.chat</span><span>. </span></p>
            <p></p>
            <p><span>A note about accuracy: Services like</span><span> Faster</span><span> generate responses by reading
                    a user’s request and, in response, predicting the words most likely to appear next. In some cases,
                    the words most likely to appear next may not be the most factually accurate. For this reason, you
                    should not rely on the factual accuracy of output from our models. If you notice that</span><span>
                    Faster</span><span> output contains factually inaccurate information about you and you would like us
                    to correct the inaccuracy, you may submit a correction request through to
                </span><span>support</span><span>&#64;</span><span>faster</span><span>.</span><span>chat</span><span>. Given
                    the technical complexity of how our models work, we may not be able to correct the inaccuracy in
                    every instance. In that case, you may request that we remove your Personal Information
                    from</span><span> Faster’s</span><span> output by </span><span>submitting a request through to
                    support&#64;faster.chat</span><span> </span></p>
            <p></p>
            <p><span>For information on how to exercise your rights with respect to data we have collected from the
                    internet to train our models, please see this help center article. </span></p>
            <p></p>
            <p class="docx_listparagraph docx-num-2-0"><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Additional U.S. state disclosures
                </span></p>
            <p class="docx_listparagraph" style="margin-left: 18pt;"></p>
            <p><span>The following table provides additional information about the categories of Personal Information we
                    collect and how we disclose that information. You can read more about the Personal Information we
                    collect in “Personal information we collect” above, how we use Personal Information in “How we use
                    personal information” above, and how we retain Personal Information in “Security and Retention”
                    below. </span></p>
            <p></p>
            <p><span>Category of Personal Information Disclosure of Personal Information Identifiers, such as your name,
                    contact details, IP address, and other device identifiers We may disclose this information to our
                    affiliates, vendors and service providers to process in accordance with our instructions; to law
                    enforcement and other third parties for the legal reasons described above; to parties involved in
                    Transactions; to corporate administrators of enterprise or team accounts; and to other users and
                    third parties you choose to share it with. Commercial Information, such as your transaction history
                    We may disclose this information to our affiliates, vendors and service providers to process in
                    accordance with our instructions; to law enforcement and other third parties for the legal reasons
                    described above; to parties involved in Transactions; and to corporate administrators of enterprise
                    or team accounts. Network Activity Information, such as </span><span>Content and how you interact
                    with our Services We may disclose this information to our affiliates, vendors and service providers
                    to process in accordance with our instructions; to law enforcement and other third parties for the
                    legal reasons described above; to parties involved in Transactions; and to other users and third
                    parties you choose to share it with. Geolocation Data We may disclose this information to our
                    affiliates, vendors and service providers to process in accordance with our instructions; to law
                    enforcement and other third parties for the legal reasons described above; and to parties involved
                    in Transactions. Your account login credentials and payment card information (Sensitive Personal
                    Information) We disclose this information to our affiliates, vendors and service providers, law
                    enforcement, and parties involved in Transactions. </span></p>
            <p></p>
            <p><span>To the extent provided for by local law and subject to applicable exceptions, individuals may have
                    the following privacy rights in relation to their Personal Information: </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>The right to know information about our processing of your
                    Personal Information, including the specific pieces of Personal Information that we have collected
                    from you; </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>The right to request deletion of your Personal Information;
                </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>The right to correct your Personal Information; and </span>
            </p>
            <p class="docx_listparagraph docx-num-3-0"><span>The right to be free from discrimination relating to the
                    exercise of any of your privacy rights. </span></p>
            <p class="docx_listparagraph"></p>
            <p><span>We don’t “sell” Personal Information or “share” Personal Information for cross-contextual
                    behavioral advertising (as those terms are defined under applicable local law). We also don’t
                    process sensitive Personal Information for the purposes of inferring characteristics about a
                    consumer. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Exercising Your Rights.</span><span> To the extent applicable under
                    local law, you can exercise privacy rights described in this section by submitting a request
                </span><span>through</span><span> to</span><span> support&#64;faster.chat</span><span>. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Verification.</span><span> In order to protect your Personal Information
                    from unauthorized access, change, or deletion, we may require you to verify your credentials before
                    you can submit a request to know, correct, or delete Personal Information. If you do not have an
                    account with us, or if we suspect fraudulent or malicious activity, we may ask you to provide
                    additional Personal Information and proof of residency for verification. If we cannot verify your
                    identity, we will not be able to honor your request. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Authorized Agents.</span><span> You may also submit a rights request
                    through an authorized agent. If you do so, the agent must present signed written permission to act
                    on your behalf and you may also be required to independently verify your identity and submit proof
                    of your residency with us. Authorized agent requests can be submitted to
                </span><span>support&#64;faster.chat</span></p>
            <p></p>
            <p><span style="font-weight: bold;">Appeals.</span><span> Depending on where you live, you may have the
                    right to appeal a decision we make relating to requests to exercise your rights under applicable
                    local law. To appeal a decision, please send your request to </span><span>support&#64;faster.chat</span>
            </p>
            <p></p>
            <p class="docx_listparagraph docx-num-2-0"><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Children </span></p>
            <p></p>
            <p><span>Our Service is not directed to children under the age of 13.</span><span> Polybius</span><span>
                    does not knowingly collect Personal Information from children under the age of 13. If you have
                    reason to believe that a child under the age of 13 has provided Personal Information to</span><span>
                    Polybius</span><span> through the Service, please email us at
                </span><span>support&#64;faster.chat</span><span> We will investigate any notification and if appropriate,
                    delete the Personal Information from our systems. If you are 13 or older, but under 18, you must
                    have permission from your parent or guardian to use our Services. </span></p>
            <p></p>
            <p class="docx_listparagraph docx-num-2-0"><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Links to other websites </span></p>
            <p></p>
            <p><span>The Service may contain links to other websites not operated or controlled by</span><span>
                    Polybius</span><span>, including social media services (“Third Party Sites”). The information that
                    you share with Third Party Sites will be governed by the specific privacy policies and terms of
                    service of the Third Party Sites and not by this Privacy Policy. By providing these links we do not
                    imply that we endorse or have reviewed these sites. Please contact the Third Party Sites directly
                    for information on their privacy practices and policies. </span></p>
            <p></p>
            <p class="docx_listparagraph docx-num-2-0"><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Security and Retention </span></p>
            <p></p>
            <p><span>We implement commercially reasonable technical, administrative, and organizational measures to
                    protect Personal Information both online and offline from loss, misuse, and unauthorized access,
                    disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully
                    secure or error free. In particular, email sent to or from us may not be secure. Therefore, you
                    should take special care in deciding what information you send to us via the Service or email. In
                    addition, we are not responsible for circumvention of any privacy settings or security measures
                    contained on the Service, or third-party websites. </span></p>
            <p></p>
            <p><span>We’ll retain your Personal Information for only as long as we need in order to provide our Service
                    to you, or for other legitimate business purposes such as resolving disputes, safety and security
                    reasons, or complying with our legal obligations. How long we retain Personal Information will
                    depend on a number of factors, such as the amount, nature, and sensitivity of the information, the
                    potential risk of harm from unauthorized use or disclosure, our purpose for processing the
                    information, and any legal requirements. </span></p>
            <p></p>
            <p class="docx_listparagraph docx-num-2-0"><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">International users </span></p>
            <p class="docx_listparagraph" style="margin-left: 18pt;"></p>
            <p><span>By using our Service, you understand and acknowledge that your Personal Information will be
                    processed and stored in our facilities and servers in the United States and may be disclosed to our
                    service providers and affiliates in other jurisdictions. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Legal Basis for Processing.</span><span> Our legal bases for processing
                    your Personal Information include: </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>Performance of a contract with you when we provide and
                    maintain our Services. When we process Account Information, Content, and Technical Information
                    solely to provide our Services to you, this information is necessary to be able to provide our
                    Services. If you do not provide this information, we may not be able to provide our Services to you.
                </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>Our legitimate interests in protecting our Services from
                    abuse, fraud, or security risks, or in developing, improving, or promoting our Services, including
                    when we train our models. This may include the processing of Account Information, Content, Social
                    Information, and Technical Information. Read our instructions on how you can opt out of our use of
                    your information to train our models. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>Your consent when we ask for your consent to process your
                    Personal Information for a specific purpose that we communicate to you. You have the right to
                    withdraw your consent at any time. </span></p>
            <p class="docx_listparagraph docx-num-3-0"><span>Compliance with our legal obligations when we use your
                    Personal Information to comply with applicable law or when we protect our or our affiliates’,
                    users’, or third parties’ rights, safety, and property. </span></p>
            <p style="margin-left: 18pt;"></p>
            <p style="margin-left: 18pt;"><span style="font-weight: bold;">Data Transfers</span><span>. Where required,
                    we will use appropriate safeguards for transferring Personal Information outside of certain
                    countries. We will only transfer Personal Information pursuant to a legally valid transfer
                    mechanism. </span></p>
            <p style="margin-left: 18pt;"></p>
            <p style="margin-left: 18pt;"><span style="font-weight: bold;">Data Protection Officer.</span><span> You can
                    contact our data protection officer </span><span>at support&#64;faster.chat</span><span> in matters
                    related to Personal Information processing. </span></p>
            <p style="margin-left: 18pt;"></p>
            <p class="docx_listparagraph docx-num-2-0"><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Changes to the privacy policy </span>
            </p>
            <p class="docx_listparagraph" style="margin-left: 18pt;"></p>
            <p><span>We may update this Privacy Policy from time to time. When we do, we will post an updated version on
                    this page, unless another type of notice is required by applicable law. </span></p>
            <p style="margin-left: 18pt;"></p>
            <p class="docx_listparagraph docx-num-2-0"><span
                    style="font-weight: bold; min-height: 14pt; font-size: 14pt;">How to contact us </span></p>
            <p class="docx_listparagraph" style="margin-left: 18pt;"></p>
            <p><span>Please contact support if you have any questions or concerns not already addressed in this Privacy
                    Policy.</span></p>
        </article>
    </section>
</div>