import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Component, Input, Output, NgModule, forwardRef, Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
class NgxTurnstileService {
  constructor() {}
  static ɵfac = function NgxTurnstileService_Factory(t) {
    return new (t || NgxTurnstileService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NgxTurnstileService,
    factory: NgxTurnstileService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTurnstileService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
const CALLBACK_NAME = 'onloadTurnstileCallback';
class NgxTurnstileComponent {
  elementRef;
  zone;
  siteKey;
  action;
  cData;
  theme = 'auto';
  version = '0';
  tabIndex;
  appearance = 'always';
  resolved = new EventEmitter();
  errored = new EventEmitter();
  widgetId;
  constructor(elementRef, zone) {
    this.elementRef = elementRef;
    this.zone = zone;
  }
  _getCloudflareTurnstileUrl() {
    if (this.version === '0') {
      return 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    }
    throw 'Version not defined in ngx-turnstile component.';
  }
  ngAfterViewInit() {
    let turnstileOptions = {
      sitekey: this.siteKey,
      theme: this.theme,
      tabindex: this.tabIndex,
      action: this.action,
      cData: this.cData,
      appearance: this.appearance,
      callback: token => {
        this.zone.run(() => this.resolved.emit(token));
      },
      'error-callback': errorCode => {
        this.zone.run(() => this.errored.emit(errorCode));
        // Returning false causes Turnstile to log error code as a console warning.
        return false;
      },
      'expired-callback': () => {
        this.zone.run(() => this.reset());
      }
    };
    const script = document.createElement('script');
    window[CALLBACK_NAME] = () => {
      if (!this.elementRef?.nativeElement) {
        return;
      }
      this.widgetId = window.turnstile.render(this.elementRef.nativeElement, turnstileOptions);
    };
    script.src = `${this._getCloudflareTurnstileUrl()}?render=explicit&onload=${CALLBACK_NAME}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }
  reset() {
    if (this.widgetId) {
      this.resolved.emit(null);
      window.turnstile.reset(this.widgetId);
    }
  }
  ngOnDestroy() {
    if (this.widgetId) {
      window.turnstile.remove(this.widgetId);
    }
  }
  static ɵfac = function NgxTurnstileComponent_Factory(t) {
    return new (t || NgxTurnstileComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: NgxTurnstileComponent,
    selectors: [["ngx-turnstile"]],
    inputs: {
      siteKey: "siteKey",
      action: "action",
      cData: "cData",
      theme: "theme",
      version: "version",
      tabIndex: "tabIndex",
      appearance: "appearance"
    },
    outputs: {
      resolved: "resolved",
      errored: "errored"
    },
    exportAs: ["ngx-turnstile"],
    decls: 0,
    vars: 0,
    template: function NgxTurnstileComponent_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTurnstileComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-turnstile',
      template: ``,
      exportAs: 'ngx-turnstile'
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], {
    siteKey: [{
      type: Input
    }],
    action: [{
      type: Input
    }],
    cData: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    version: [{
      type: Input
    }],
    tabIndex: [{
      type: Input
    }],
    appearance: [{
      type: Input
    }],
    resolved: [{
      type: Output
    }],
    errored: [{
      type: Output
    }]
  });
})();
class NgxTurnstileModule {
  static ɵfac = function NgxTurnstileModule_Factory(t) {
    return new (t || NgxTurnstileModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxTurnstileModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTurnstileModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxTurnstileComponent],
      imports: [],
      exports: [NgxTurnstileComponent]
    }]
  }], null, null);
})();
class NgxTurnstileValueAccessorDirective {
  turnstileComp;
  onChange;
  onTouched;
  resolved = false;
  constructor(turnstileComp) {
    this.turnstileComp = turnstileComp;
  }
  ngOnInit() {
    this.turnstileComp.resolved.subscribe(token => {
      this.resolved = !!token;
      if (this.onChange) {
        this.onChange(token);
      }
      if (this.onTouched) {
        this.onTouched();
      }
    });
  }
  // Prevent form control from setting token value
  writeValue(value) {
    // reset turnstile component if form control sets the value after already receiving a valid token
    if (this.resolved) {
      this.resolved = false;
      this.turnstileComp.reset();
    }
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  static ɵfac = function NgxTurnstileValueAccessorDirective_Factory(t) {
    return new (t || NgxTurnstileValueAccessorDirective)(i0.ɵɵdirectiveInject(NgxTurnstileComponent));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NgxTurnstileValueAccessorDirective,
    selectors: [["ngx-turnstile", "formControl", ""], ["ngx-turnstile", "formControlName", ""], ["ngx-turnstile", "ngModel", ""]],
    features: [i0.ɵɵProvidersFeature([{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgxTurnstileValueAccessorDirective),
      multi: true
    }])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTurnstileValueAccessorDirective, [{
    type: Directive,
    args: [{
      selector: 'ngx-turnstile[formControl], ngx-turnstile[formControlName], ngx-turnstile[ngModel]',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NgxTurnstileValueAccessorDirective),
        multi: true
      }]
    }]
  }], () => [{
    type: NgxTurnstileComponent
  }], null);
})();
class NgxTurnstileFormsModule {
  static ɵfac = function NgxTurnstileFormsModule_Factory(t) {
    return new (t || NgxTurnstileFormsModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxTurnstileFormsModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [NgxTurnstileModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTurnstileFormsModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxTurnstileValueAccessorDirective],
      imports: [NgxTurnstileModule],
      exports: [NgxTurnstileValueAccessorDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-turnstile
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxTurnstileComponent, NgxTurnstileFormsModule, NgxTurnstileModule, NgxTurnstileService, NgxTurnstileValueAccessorDirective };
