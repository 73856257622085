export const environment = {
    production: true,
    openReplayKey: 'Ou5NYIZZnwZRuNplJyeQ',  //OpenReplay Project Key
    turnstileSitekey: '0x4AAAAAAAY8Dto1lgvxKWne',
    localTurnstilePasskey: 'ChickensAreFunny',
    supabaseProjectId: 'lvavmnolenvdowkrvjua',
    supabaseEndpoint: 'https://lvavmnolenvdowkrvjua.supabase.co/rest/v1/',
    supabaseUrl: 'https://lvavmnolenvdowkrvjua.supabase.co',
    supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx2YXZtbm9sZW52ZG93a3J2anVhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTQ1Nzg5MTMsImV4cCI6MjAzMDE1NDkxM30.rm1MMIaHL3NMxoKU_gAT7zxZAMgZYEO2lf000hq-Pvs', // Public Key for the Supabase API
    chatCompletionEndpoint: 'https://lvavmnolenvdowkrvjua.supabase.co/functions/v1/chat-completion'
}