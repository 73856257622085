<div class="relative">
    <div class="flex flex-col items-center h-svh w-full pt-64">
        <ngx-turnstile [siteKey]="sitekey" theme="light" (resolved)="signInAnonymously($event)"></ngx-turnstile>
    </div>
    
    <div class="absolute top-3 left-2">
        <button #dropdownButton type="button"
            class="inline-flex items-center rounded-full hover:bg-gray-50 py-1 px-2 text-lg font-semibold tracking-light text-gray-900 hover:ring-1 ring-inset ring-gray-500/10 space-x-2 sm:text-xl"
            aria-haspopup="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                class="w-7 h-7 text-yellow-500">
                <path fill-rule="evenodd"
                    d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z"
                    clip-rule="evenodd" />
            </svg>
            <span>Faster</span>
            <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd" />
            </svg>
        </button>
    </div>
    
</div>