import { Injectable } from '@angular/core';
import { SupabaseService } from '../supabase/supabase.service';
import { Database } from '../../schema';
import { Observable, from, map, tap } from 'rxjs';
import { Message } from '../message/message.service';
import { FunctionRegion } from '@supabase/supabase-js';

export type Session = {
  id: string;
  name: string;
  last_message: string;
  user_id: string;
  created_at: string;
  updated_at: string;
}

export type SessionGroup = {
  date: 'Today' | 'Yesterday' | 'Previous 7 Days' | 'Previous 30 Days' | 'Older';
  sessions: Session[];
}

export type CreateSessionInput = Database['public']['Tables']['sessions']['Insert'];
export type UpdateSessionInput = Database['public']['Tables']['sessions']['Update'];

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private supabase: SupabaseService) { }

  public getSession(sessionId: string): Observable<Session> {
    return from(this.supabase.supabase.from('sessions').select('*').eq('id', sessionId).single()).pipe(map(response => response.data as Session));
  }

  public updateSession(session: UpdateSessionInput): Observable<Session> {
    return from(this.supabase.supabase.from('sessions').update(session).match({ id: session.id }).select().single()).pipe(map(response => response.data as Session));
  }

  public nameSession(messages: Array<Message>, sessionId: string): Observable<Session> {
    return from(this.supabase.supabase.functions.invoke('name-session', {
      region: FunctionRegion.UsEast1,
      method: 'POST',
      body: JSON.stringify({ messages: messages.slice(0, 2), sessionId })
    })).pipe(map(response => response.data as Session));
  }

  public loadGroupedSession(): Observable<SessionGroup[]> {
    return this._loadSessions().pipe(map(sessions => {
      const today: Session[] = [];
      const yesterday: Session[] = [];
      const previous_week: Session[] = [];
      const previous_30_days: Session[] = [];
      const older: Session[] = [];

      const now = new Date();
      const todayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const yesterdayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      const pastWeekDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      const previous30DaysDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);

      sessions.forEach(session => {
        const sessionDate = new Date(session.last_message);

        if (sessionDate >= todayDate) {
          today.push(session);
        } else if (sessionDate >= yesterdayDate) {
          yesterday.push(session);
        } else if (sessionDate >= pastWeekDate) {
          previous_week.push(session);
        } else if (sessionDate >= previous30DaysDate) {
          previous_30_days.push(session);
        } else {
          older.push(session);
        }
      });

      return [
        { date: 'Today', sessions: today },
        { date: 'Yesterday', sessions: yesterday },
        { date: 'Previous 7 Days', sessions: previous_week },
        { date: 'Previous 30 Days', sessions: previous_30_days },
        { date: 'Older', sessions: older }
      ];
    }));
  }

  private _loadSessions(): Observable<Session[]> {
    return from(this.supabase.supabase.from('sessions').select('*').order('last_message', { ascending: false })).pipe(map(response => response.data as Session[]));
  }

  public createSession(session: CreateSessionInput): Observable<Session> {
    return from(this.supabase.supabase.from('sessions').insert(session).select().single()).pipe(tap(response => console.log(response)), map(response => response.data as Session));
  }
}
