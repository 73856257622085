<button #dropdownButton type="button"
    class="inline-flex items-center rounded-full hover:bg-gray-50 py-2 px-2 text-lg font-semibold tracking-light text-gray-900 hover:ring-1 ring-inset ring-gray-500/10 space-x-2"
    (click)="dropdownOpen = !dropdownOpen" (blur)="close()" aria-haspopup="true">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-7 h-7 text-yellow-500">
        <path fill-rule="evenodd"
            d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z"
            clip-rule="evenodd" />
    </svg>
    <span *ngIf="model == 'llama3-8b-8192'">Faster</span>
    <span *ngIf="model == 'llama3-70b-8192'">Faster+</span>
    <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clip-rule="evenodd" />
    </svg>
</button>

<div class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-out duration-100 transform"
    [class.opacity-0]="!dropdownOpen" [class.scale-95]="!dropdownOpen" [class.opacity-100]="dropdownOpen"
    [class.scale-100]="dropdownOpen" *ngIf="dropdownOpen" role="menu" aria-orientation="vertical"
    aria-labelledby="menu-button" tabindex="-1">
    <ul class="w-full h-full rounded-md">
        <li (click)="selectModel('llama3-8b-8192')"
            class="inline-flex justify-start items-center px-4 py-2 space-x-2 bg-white hover:bg-gray-50 cursor-pointer w-full">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                class="w-5 h-5 text-green-500">
                <path
                    d="M3.288 4.818A1.5 1.5 0 0 0 1 6.095v7.81a1.5 1.5 0 0 0 2.288 1.276l6.323-3.905c.155-.096.285-.213.389-.344v2.973a1.5 1.5 0 0 0 2.288 1.276l6.323-3.905a1.5 1.5 0 0 0 0-2.552l-6.323-3.906A1.5 1.5 0 0 0 10 6.095v2.972a1.506 1.506 0 0 0-.389-.343L3.288 4.818Z" />
            </svg>

            <div>
                <h2 class="font-base text-base">Faster</h2>
                <p class="text-xs font-light">Ultra Fast. Llama 8B</p>
            </div>
        </li>
        <li (click)="selectModel('llama3-70b-8192')"
            class="inline-flex justify-start items-center px-4 py-2 space-x-2 bg-white hover:bg-gray-50 cursor-pointer w-full">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-blue-500">
                <path
                    d="M15.98 1.804a1 1 0 0 0-1.96 0l-.24 1.192a1 1 0 0 1-.784.785l-1.192.238a1 1 0 0 0 0 1.962l1.192.238a1 1 0 0 1 .785.785l.238 1.192a1 1 0 0 0 1.962 0l.238-1.192a1 1 0 0 1 .785-.785l1.192-.238a1 1 0 0 0 0-1.962l-1.192-.238a1 1 0 0 1-.785-.785l-.238-1.192ZM6.949 5.684a1 1 0 0 0-1.898 0l-.683 2.051a1 1 0 0 1-.633.633l-2.051.683a1 1 0 0 0 0 1.898l2.051.684a1 1 0 0 1 .633.632l.683 2.051a1 1 0 0 0 1.898 0l.683-2.051a1 1 0 0 1 .633-.633l2.051-.683a1 1 0 0 0 0-1.898l-2.051-.683a1 1 0 0 1-.633-.633L6.95 5.684ZM13.949 13.684a1 1 0 0 0-1.898 0l-.184.551a1 1 0 0 1-.632.633l-.551.183a1 1 0 0 0 0 1.898l.551.183a1 1 0 0 1 .633.633l.183.551a1 1 0 0 0 1.898 0l.184-.551a1 1 0 0 1 .632-.633l.551-.183a1 1 0 0 0 0-1.898l-.551-.184a1 1 0 0 1-.633-.632l-.183-.551Z" />
            </svg>


            <div>
                <h2 class="font-base text-base">Faster+</h2>
                <p class="text-xs font-light">Higher Quality. Llama 70B</p>
            </div>
        </li>
    </ul>
</div>