import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { Subject, takeUntil } from 'rxjs';
import { SupabaseService } from '../../services/supabase/supabase.service';
import { User } from '@supabase/supabase-js';
import { StripeService } from '../../services/stripe/stripe.service';
import { CommonModule } from '@angular/common';
import { SignUpModalComponent } from '../sign-up-modal/sign-up-modal.component';
import { FadeInAnimation } from '../../animations';

@Component({
  selector: 'app-upgrade-modal',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './upgrade-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeInAnimation]
})
export class UpgradeModalComponent {

  @ViewChild('modalRef') modalRef!: ElementRef | undefined;

  private _destroyed$: Subject<void> = new Subject<void>();

  public processing: boolean = false;
  public user: User | null = null;

  constructor(private modalService: ModalService, private cdr: ChangeDetectorRef,
              private stripeService: StripeService, private supabaseService: SupabaseService) {

  }

  ngOnInit(): void {
    this._getUser();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public upgrade(): void {
    if (this.user?.is_anonymous) {
      this.modalService.open(SignUpModalComponent);
      return;
    }

    this.processing = true;
    this.cdr.detectChanges();

    this.stripeService.createUpgradeLink().subscribe(response => {
      window.location.assign(response.url);
      this.processing = false;
      this.cdr.detectChanges();
    })
  }

  private _getUser(): void {
    this.supabaseService.user$.pipe(takeUntil(this._destroyed$)).subscribe(user => {
      this.user = user;
      this.cdr.detectChanges();
    })
  }


  public close(): void {
    this.modalService.close();
  }

  @HostListener('document:keydown.escape', ['$event'])
  public onEscapeKeyDown(event: KeyboardEvent) {
    this.close();
  }

  // @HostListener('document:click', ['$event'])
  // onDocumentClick(event: MouseEvent): void {
  //   if (!this.modalRef) {
  //     return;
  //   }

  //   const target = event.target as Node;
  //   if (!this.modalRef.nativeElement?.contains(target)) {
  //     this.close();
  //   }
  // }

}
