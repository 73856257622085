import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SupabaseService } from '../../services/supabase/supabase.service';
import { environment } from '../../../environments/environment';
import { ModalService } from '../../services/modal/modal.service';
import { AuthResponse } from '@supabase/supabase-js';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { NgxTurnstileModule } from 'ngx-turnstile';
import { FadeInAnimation } from '../../animations';
import { MODAL_DATA } from '../../outlets/modal-outlet/modal-outlet.component';
import { SignUpModalComponent } from '../sign-up-modal/sign-up-modal.component';

export type SignInModalData = {
  turnstileToken: string | null;
}

@Component({
  selector: 'app-sign-in-modal',
  standalone: true,
  imports: [
    CommonModule,
    NgxTurnstileModule,
    ReactiveFormsModule
  ],
  templateUrl: './sign-in-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeInAnimation]
})
export class SignInModalComponent implements OnInit, AfterViewInit {

  @ViewChild('modalRef') modalRef!: ElementRef | undefined;

  public sitekey = environment.turnstileSitekey;
  public turnstileToken: string | null = window.location.origin.includes('localhost') ? 'test' : null;

  constructor(@Inject(MODAL_DATA) private data: SignInModalData, private supabase: SupabaseService, private notifications: NotificationsService, private modalService: ModalService) {

  }

  ngOnInit(): void {
    this.turnstileToken = this.data.turnstileToken;
  }

  ngAfterViewInit(): void {
    this.signInForm.updateValueAndValidity();
  }

  public signInForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });


  public signInWithGoogle(): void {
    this.supabase.signOut().then(() => {
      this.supabase.signInWithGoogle()
    })
  }

  public signIn(): void {
    if (!this.turnstileToken) {
      return;
    }

    const email = this.signInForm.get('email')?.value;
    const password = this.signInForm.get('password')?.value;

    this.supabase.signOut().then(() => {
      if (!this.turnstileToken) {
        return;
      }

      this.supabase.supabase.auth.signInWithPassword({
        email: email,
        password: password,
        options: {
          captchaToken: this.turnstileToken,
        }
      }).then((response: AuthResponse) => {
        if (response.error) {
          this.notifications.error('Error', response.error.message);
        } else {
          this.notifications.success('Success', `Succesfully signed in as ${email}`);
          this.close();
        }

      }).catch((error) => {
        console.error('Error signing in:', error);
      }
      )
    });
  }

  public setTurnstileToken(turnstileToken: string | null): void {
    this.turnstileToken = turnstileToken;
  }

  public openSignUpModal(): void {
    this.modalService.open(SignUpModalComponent, { data: { turnstileToken: this.turnstileToken } });
  }

  @HostListener('document:keydown.escape', ['$event'])
  public onEscapeKeyDown(event: KeyboardEvent) {
    this.close();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.modalRef) {
      return;
    }

    const target = event.target as Node;
    if (!this.modalRef.nativeElement?.contains(target)) {
      this.close();
    }
  }



  public close(): void {
    this.modalService.close();
  }

}
