import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type Notification = {
  type: 'success' | 'error';
  title: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public notifications: Subject<Notification> = new Subject<Notification>();

  constructor() { }

  public success(title: string, message: string): void {
    this.notifications.next({ type: 'success', title, message });
  }

  public error(title: string, message: string): void {
    this.notifications.next({ type: 'error', title, message });
  }

}
