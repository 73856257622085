import { Routes } from '@angular/router';
import { SessionComponent } from './modules/session/session/session.component';
import { VerifyTurnstileComponent } from './modules/auth/verify-turnstile/verify-turnstile.component';
import { TermsOfServiceComponent } from './modules/policies/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './modules/policies/privacy-policy/privacy-policy.component';
import { SignInComponent } from './modules/auth/sign-in/sign-in.component';
import { SignUpComponent } from './modules/auth/sign-up/sign-up.component';
import { VerificationEmailSentComponent } from './modules/auth/verification-email-sent/verification-email-sent.component';

export const routes: Routes = [
    {
        path: "authenticate",
        component: VerifyTurnstileComponent
    },
    {
        path: "sign-in",
        component: SignInComponent,
    },
    {
        path: 'sign-up',
        component: SignUpComponent,
    },
    {
        path: 'verification-email-sent',
        component: VerificationEmailSentComponent,
    },
    {
        path: ":sessionId",
        component: SessionComponent,
    },
    {
        path: "policies/terms-of-service",
        component: TermsOfServiceComponent,
    },
    {
        path: "policies/privacy-policy",
        component: PrivacyPolicyComponent,
    },
    {
        path: "",
        redirectTo: "/new",
        pathMatch: "full"
    }
];
