import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SupabaseService } from '../../../../services/supabase/supabase.service';
import { Subject, takeUntil } from 'rxjs';
import { User } from '@supabase/supabase-js';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalService } from '../../../../services/modal/modal.service';
import { SignUpModalComponent } from '../../../../modals/sign-up-modal/sign-up-modal.component';

@Component({
  selector: 'session-auth',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './session-auth.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionAuthComponent {

  @Input() user: User | null = null;

  public dropdownOpen: boolean = false;

  constructor(private supabase: SupabaseService, private modalService: ModalService, private cdr: ChangeDetectorRef) {

  }



  public close(): void {
    setTimeout(() => {
      this.dropdownOpen = false;
      this.cdr.detectChanges();
    }, 250);
  }

  public signOut(): void {
    this.supabase.signOut();
  }

  public openSignUpModal(): void {
    this.modalService.open(SignUpModalComponent);
  }


}
