<ul role="list" class="mt-6 grid grid-cols-1 space-y-8 px-4">
    <li class="flow-root">
        <div (click)="onClick.emit('Write a blog post about the key trends in AI')"
            class="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-blue-500 bg-gray-50 hover:bg-gray-200 cursor-pointer">
            <div class="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-blue-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="h-7 w-7 text-white">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                </svg>

            </div>
            <div>
                <h3 class="text-sm font-medium text-gray-900">
                    <div class="focus:outline-none">
                        <span class="absolute inset-0" aria-hidden="true"></span>
                        <span>Write a blog post</span>
                        <span aria-hidden="true"> &rarr;</span>
                    </div>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Write a blog post about the key trends in AI</p>
            </div>
        </div>
    </li>
    <li class="flow-root">
        <div (click)="onClick.emit('Help me write a compelling sales script for my business')"
            class="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-blue-500 bg-gray-50 hover:bg-gray-200 cursor-pointer">
            <div class="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-green-500 ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-7 h-7 text-white">
                    <path fill-rule="evenodd"
                        d="M2 3.5A1.5 1.5 0 0 1 3.5 2h1.148a1.5 1.5 0 0 1 1.465 1.175l.716 3.223a1.5 1.5 0 0 1-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 0 0 6.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 0 1 1.767-1.052l3.223.716A1.5 1.5 0 0 1 18 15.352V16.5a1.5 1.5 0 0 1-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 0 1 2.43 8.326 13.019 13.019 0 0 1 2 5V3.5Z"
                        clip-rule="evenodd" />
                </svg>
            </div>
            <div>
                <h3 class="text-sm font-medium text-gray-900">
                    <div class="focus:outline-none">
                        <span class="absolute inset-0" aria-hidden="true"></span>
                        <span>Create a Sales Script</span>
                        <span aria-hidden="true"> &rarr;</span>
                    </div>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Help me write a compelling sales script for my business</p>
            </div>
        </div>
    </li>
</ul>