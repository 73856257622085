import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'model-selection-dropdown',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './model-selection-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelSelectionDropdownComponent {

  public dropdownOpen: boolean = false;
  @Input() model: 'llama3-8b-8192' | 'llama3-70b-8192' = 'llama3-8b-8192';
  @Output() modelChange: EventEmitter<'llama3-8b-8192' | 'llama3-70b-8192'> = new EventEmitter<'llama3-8b-8192' | 'llama3-70b-8192'>();
  @ViewChild('dropdownButton') dropdownButton: ElementRef<HTMLButtonElement> | undefined;

  constructor(private cdr: ChangeDetectorRef) {

  }

  public close(): void {
    setTimeout(() => {
      this.dropdownOpen = false;
      this.cdr.detectChanges();
    }, 250);
  }

  @HostListener('document:touchstart', ['$event.target'])
  handleTouchstart(targetElement: any) {
    if (!this.dropdownButton?.nativeElement.contains(targetElement)) {
      this.dropdownOpen = false;
      this.cdr.detectChanges();
    }
  }

  public selectModel(model: 'llama3-8b-8192' | 'llama3-70b-8192'): void {
    this.model = model;
    this.modelChange.emit(model);
    this.close();
  }
}
