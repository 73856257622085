import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { SseClient } from 'ngx-sse-client';
import { Observable, Subject, from, map, switchMap, take, takeUntil, tap } from 'rxjs';
import { SupabaseService } from '../supabase/supabase.service';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../environments/environment';
import { Database } from '../../schema';
import { Session, SessionService } from '../session/session.service';

export type Message = {
  id: string;
  date: string;
  role: 'User' | 'Assistant';
  content: string;
  status: 'InProgress' | 'Completed' | 'Failed';
  session_id: string;
  user_id: string;
  created_at: string;
  updated_at: string;
}

export type CreateMessageInput = Database['public']['Tables']['messages']['Insert'];
export type UpdateMessageInput = Database['public']['Tables']['messages']['Update'];


export enum SSEEvent {
  Complete = 'Complete',
  Failed = 'Failed',
  Message = 'Message',
  RateLimit = 'RateLimit'
}

export interface SSEEventPayload {
  event: SSEEvent;
  data: any;
}


@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private sseClient: SseClient, private sessionService: SessionService, private supabase: SupabaseService) {

  }

  public createMessage(message: CreateMessageInput): Observable<Message> {
    return from(this.supabase.supabase.from('messages').insert(message).select().single()).pipe(map(response => response.data as Message));
  }

  public createMessageInNewSession(message: CreateMessageInput): Observable<{ 'session': Session, 'message': Message }> {
    const session = { id: message.session_id, name: null, user_id: message.user_id }
    return this.sessionService.createSession(session).pipe(take(1), switchMap(session =>
      this.createMessage(message).pipe(
        map(message => ({ 'session': session, 'message': message }))
      )
    ));
  }

  public loadMessages(sessionId: string): Observable<Array<Message>> {
    return from(this.supabase.supabase.from('messages').select().eq('session_id', sessionId).order('date', { ascending: true }).limit(1000)).pipe(map(response => response.data as Array<Message>));
  }

  public stream(messages: Array<Message>, session_id: string, model: 'llama3-8b-8192' | 'llama3-70b-8192', temperature: number = 0.25): Observable<Message> {
    if (!this.supabase.userId) {
      return new Observable<Message>();
    }

    const body = { messages: this.formatMessages(messages), model, temperature };
    const headers = { 'x-region': 'us-east-1' }
    const streamComplete: Subject<void> = new Subject<void>();
    const stream$: Subject<Message> = new Subject<Message>();

    const message: Message = {
      id: uuidv4(),
      date: this.getCurrentUtcTimestamp(),
      role: 'Assistant',
      content: '',
      status: 'InProgress',
      session_id,
      user_id: this.supabase.userId,
      created_at: this.getCurrentUtcTimestamp(),
      updated_at: this.getCurrentUtcTimestamp()
    }

    this.sseClient.stream(environment.chatCompletionEndpoint, { keepAlive: false, reconnectionDelay: 10_000, responseType: 'event' }, { body, headers }, 'POST').pipe(
      takeUntil(streamComplete)
    ).subscribe(
      {
        next: (event: any) => {
          // console.log(event);
          if (!event.data) {
            message.status = 'Completed';
            message.updated_at = this.getCurrentUtcTimestamp();
            stream$.next({ ...message });
            stream$.complete();
            return;
          }

          const data: SSEEventPayload = JSON.parse(event.data);

          if (data.event == SSEEvent.Message) {
            message.content += data['data'];
            stream$.next({ ...message });
          } else if (data.event == SSEEvent.RateLimit) {
            stream$.error(data['data']);
          }
        },
        error: (error: any) => {
          console.log(error);
          message.status = 'Failed';
          message.updated_at = this.getCurrentUtcTimestamp();
          stream$.next({ ...message });
        },
        complete: () => {
          console.log('Stream complete');
          message.status = 'Completed';
          message.updated_at = this.getCurrentUtcTimestamp();
          stream$.next({ ...message });
          stream$.complete();
          streamComplete.complete();
        },
      }
    );

    return stream$.asObservable();
  };

  // public formatMessages(messages: Array<Message>): Array<{ role: 'user' | 'assistant', content: string }> {
  //   return messages.map((message: Message) => {
  //     return {
  //       role: message.role === 'User' ? 'user' : 'assistant',
  //       content: message.content || ''
  //     }
  //   });
  // }
  public formatMessages(messages: Array<Message>, maxChars: number = 22000): Array<{ role: 'user' | 'assistant', content: string }> {
    let result: Array<{ role: 'user' | 'assistant', content: string }> = [];
    let charCount: number = 0;

    for (let i = messages.length - 1; i >= 0; i--) {
      const message = messages[i];
      const formattedMessage = {
        role: message.role === 'User' ? 'user' : 'assistant' as 'user' | 'assistant',
        content: message.content || ''
      };
      const messageChars = formattedMessage.content.length;

      if (charCount + messageChars <= maxChars) {
        result.unshift(formattedMessage);
        charCount += messageChars;
      } else {
        console.log('Max character limit reached');
        break;
      }
    }

    return result;
  }

  public getCurrentUtcTimestamp(): string {
    return DateTime.local().toUTC().toISO(); // Get current UTC time in ISO format with timezone
  }
}
