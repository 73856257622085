import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { User } from '@supabase/supabase-js';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { SignUpModalComponent } from '../../../../modals/sign-up-modal/sign-up-modal.component';
import { ModalService } from '../../../../services/modal/modal.service';
import { UpgradeModalComponent } from '../../../../modals/upgrade-modal/upgrade-modal.component';
import { SupabaseService } from '../../../../services/supabase/supabase.service';

@Component({
  selector: 'prompt-editor',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './prompt-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromptEditorComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  private _destroyed$: Subject<void> = new Subject<void>();

  @ViewChild('textInput') promptInput: ElementRef<HTMLInputElement> | undefined;

  @Input() disabled: boolean = false;
  @Input() user: User | null = null;
  @Input() rateLimitedUntil: Date | null = null;
  @Input() placeholder: string = 'Type something...'
  @Input() completionInProgress: boolean = false;
  @Input() sessionId: string | undefined;
  @Output() onSubmit: EventEmitter<string> = new EventEmitter<string>();

  public numRows: number = 1;
  promptControl = new FormControl('', [Validators.required]);

  constructor(private cdr: ChangeDetectorRef, private supabase: SupabaseService, private modalService: ModalService,) {

  }

  ngOnInit(): void {
    this._setPromptValue();
    this._registerPromptAutosave();
  }

  ngAfterViewInit(): void {
    this.adjustTextArea();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      if (this.disabled) {
        this.promptControl.disable();
      } else {
        this.promptControl.enable();
      }
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  // // Update the prompt control when the disabled input changes
  // public refreshSession(): void {
  //   this.supabase.supabase.auth.refreshSession().then(() => {
  //     console.log("Session Refreshed");
  //   })
  // }

  // Focus the prompt input field
  public focus(): void {
    if (this.promptInput) {
      this.promptInput.nativeElement.focus();
    }
  }

  // Click enter to submit the prompt
  @HostListener("document:keydown", ["$event"]) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Enter" && !event.shiftKey && !this.disabled && this.promptControl.value !== "") {
      event.preventDefault(); // Prevent default action to handle the event here (especially useful to avoid form submission)
      this.submitPrompt();
    }
  }

  public submitPrompt(): void {
    if (this.user?.is_anonymous && this.user.app_metadata['numCompletions'] > 10) {
      this.modalService.open(SignUpModalComponent);
      return;
    }

    if (this.rateLimitedUntil) {
      this.modalService.open(UpgradeModalComponent);
      return;
    }

    if (this.rateLimitedUntil) {
      this.modalService.open(UpgradeModalComponent);
      return;
    }

    const prompt = this.promptControl.value!.trim();
    this.onSubmit.emit(prompt);
    this.promptControl.reset();
    this.cdr.detectChanges();
    this.adjustTextArea();
    this._resetPromptValue();
  }

  @HostListener('input', ['$event.target'])
  adjustTextArea() {
    const textarea = this.promptInput?.nativeElement;
    if (!textarea) { return }
    textarea.style.height = 'auto'; // Reset the height allows the textarea to shrink when deleting text
    textarea.style.height = Math.min(textarea.scrollHeight, 350) + 'px'; // Set the textarea height based on the scroll height, capped at 400px
  }

  private _resetPromptValue(): void {
    localStorage.removeItem(`${this.sessionId}-prompt`);
  }

  private _setPromptValue(): void {
    const prompt = localStorage.getItem(`${this.sessionId}-prompt`);
    if (!prompt) { return }
    this.promptControl.setValue(prompt);
  }

  private _registerPromptAutosave(): void {
    this.promptControl.valueChanges.pipe(takeUntil(this._destroyed$), debounceTime(300)).subscribe((value: string | null) => {
      if (!this.sessionId) { return }
      if (!value) { return }
      localStorage.setItem(`${this.sessionId}-prompt`, value);
    })
  }



}
