<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <!--
      Background backdrop, show/hide based on modal state.
  
      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <!--
          Modal panel, show/hide based on modal state.
  
          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
            <ngx-turnstile [siteKey]="sitekey" theme="light" (resolved)="setTurnstileToken($event)"
                *ngIf="!turnstileToken"></ngx-turnstile>
            <div #modalRef
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-96" *ngIf="turnstileToken" @fadeIn>
                <div class="px-6 py-6 shadow" >
                    <div class="sm:mx-auto sm:w-full sm:max-w-md">
                        <!-- <img class="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=blue&shade=600"
                            alt="Your Company"> -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                            class="mx-auto h-10 text-yellow-500">
                            <path fill-rule="evenodd"
                                d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z"
                                clip-rule="evenodd" />
                        </svg>
                        <h2 class="mt-6 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">Create a
                            free account
                        </h2>
                    </div>
                    <form class="space-y-6 mt-6" [formGroup]="signUpForm">
                        <div>
                            <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                            <div class="mt-2">
                                <input id="email" name="email" type="email" autocomplete="email" formControlName="email"
                                    required placeholder="you@example.com"
                                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6">
                            </div>
                        </div>
                        <div>
                            <label for="password"
                                class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                            <div class="mt-2">
                                <input id="password" name="password" type="password" autocomplete="current-password"
                                    formControlName="password" required placeholder="•••••••••"
                                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6">
                            </div>
                            <div class="flex flex-col space-y-2">
                                <span class="text-xs text-red-400"
                                    *ngIf="signUpForm.get('password')?.touched && signUpForm.get('password')?.hasError('passwordTooShort')">Password
                                    must be at least 8 characters long</span>
                                <span class="text-xs text-red-400"
                                    *ngIf="signUpForm.get('password')?.touched && signUpForm.get('password')?.hasError('passwordNoDigit')">Password
                                    must contain at least one digit</span>
                                <span class="text-xs text-red-400"
                                    *ngIf="signUpForm.get('password')?.touched && signUpForm.get('password')?.hasError('passwordNoUppercase')">Password
                                    must contain at least one uppercase letter</span>
                                <span class="text-xs text-red-400"
                                    *ngIf="signUpForm.get('password')?.touched && signUpForm.get('password')?.hasError('passwordNoLowercase')">Password
                                    must contain at least one lowercase letter</span>
                            </div>

                        </div>

                        <div>
                            <button type="submit" [disabled]="!signUpForm.valid" (click)="signUp()"
                                class="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-gray-600 disabled:ring-gray-300">Sign
                                Up</button>
                        </div>
                    </form>

                    <div>
                        <div class="relative mt-4">
                            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                <div class="w-full border-t border-gray-200"></div>
                            </div>
                            <div class="relative flex justify-center text-sm font-medium leading-6">
                                <!-- <span class="bg-white px-6 text-gray-900">Or continue with</span> -->
                            </div>
                        </div>

                        <div class="mt-4 grid grid-cols-1 gap-4">
                            <button (click)="signUpWithGoogle()"
                                class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                                <svg class="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true">
                                    <path
                                        d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                                        fill="#EA4335" />
                                    <path
                                        d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                                        fill="#4285F4" />
                                    <path
                                        d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                                        fill="#FBBC05" />
                                    <path
                                        d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                                        fill="#34A853" />
                                </svg>
                                <span class="text-sm font-semibold leading-6">Sign Up with Google</span>
                            </button>
                        </div>
                    </div>
                    <p class="mt-6 text-center text-sm text-gray-500">
                        Already have an account?
                        <button (click)="openSignInModal()"
                            class="font-semibold leading-6 text-blue-600 hover:text-blue-500">Sign
                            in</button>
                    </p>
                </div>
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" (click)="close()"
                        class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span class="sr-only">Close</span>
                        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                            aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>