<div class="w-full">
    <div class="relative mt-2 pb-2 w-full">
        <textarea type="text" prompt="prompt" id="prompt" [rows]="numRows" [placeholder]="placeholder"
            [formControl]="promptControl" (input)="adjustTextArea()" #textInput
            class="peer block w-full border-0 bg-transparent py-1.5 text-gray-900 focus:ring-0 sm:leading-6 appearance-none resize-none sm:text-base h-auto"
            placeholder="Jane Smith"></textarea>
        <div class="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-blue-600"
            aria-hidden="true"></div>
        <button class="absolute bottom-4 right-2 text-gray-500" [disabled]="!promptControl.valid"
            (click)="submitPrompt()" [ngClass]="promptControl.valid ? 'text-gray-900' : 'text-gray-500'">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                <path fill-rule="evenodd"
                    d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
                    clip-rule="evenodd" />
            </svg>
        </button>
    </div>
    <p class="mt-2 ml-3 text-xs text-red-400"
        *ngIf="this.user && this.user.is_anonymous && this.user.app_metadata['numCompletions'] > 10">
        Please sign up for a free account to continue using Faster.chat
    </p>
    <p class="mt-2 ml-3 text-xs text-red-400"
        *ngIf="rateLimitedUntil">
        You've exceeded your free tier limit. Please upgrade to a paid plan to continue using Faster.chat.
    </p>
</div>