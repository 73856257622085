import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { SupabaseService } from '../../../services/supabase/supabase.service';
import { TurnstileService } from '../../../services/turnstile/turnstile.service';
import { NgxTurnstileComponent, NgxTurnstileModule } from 'ngx-turnstile';
import { environment } from '../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-verify-turnstile',
  standalone: true,
  imports: [
    CommonModule,
    NgxTurnstileModule
  ],
  templateUrl: './verify-turnstile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyTurnstileComponent implements AfterViewInit {

  public sitekey = environment.turnstileSitekey;

  constructor(private supabase: SupabaseService, private router: Router, private turnstile: TurnstileService) {

  }

  ngAfterViewInit(): void {
    if (window.location.origin.includes('localhost')) {
      this.signInAnonymously('test');
    }
  }


  public signInAnonymously(turnstileToken: string | null): void {
    if (!turnstileToken) {
      return;
    }

    this.supabase.supabase.auth.signInAnonymously({ options: { captchaToken: turnstileToken } }).then((response) => {
      this.router.navigate(['/new']);

    }).catch((error) => {
      console.error('Error signing in anonymously:');
    });
  }

}
