<!--docxjs library predefined styles-->
<style>
    .docx-wrapper {
        background: gray;
        padding: 30px;
        padding-bottom: 0px;
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    .docx-wrapper>section.docx {
        background: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        margin-bottom: 30px;
    }

    .docx {
        color: black;
    }

    section.docx {
        box-sizing: border-box;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        overflow: hidden;
    }

    section.docx>article {
        margin-bottom: auto;
    }

    .docx table {
        border-collapse: collapse;
    }

    .docx table td,
    .docx table th {
        vertical-align: top;
    }

    .docx p {
        margin: 0pt;
        min-height: 1em;
    }

    .docx span {
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }

    .docx a {
        color: inherit;
        text-decoration: inherit;
    }
</style><!--docxjs document theme values-->
<style>
    .docx {
        --docx-majorHAnsi-font: Calibri Light;
        --docx-minorHAnsi-font: Calibri;
        --docx-dk1-color: #000000;
        --docx-lt1-color: #FFFFFF;
        --docx-dk2-color: #44546A;
        --docx-lt2-color: #E7E6E6;
        --docx-accent1-color: #4472C4;
        --docx-accent2-color: #ED7D31;
        --docx-accent3-color: #A5A5A5;
        --docx-accent4-color: #FFC000;
        --docx-accent5-color: #5B9BD5;
        --docx-accent6-color: #70AD47;
        --docx-hlink-color: #0563C1;
        --docx-folHlink-color: #954F72;
    }
</style><!--docxjs document styles-->
<style>
    .docx span {
        font-family: var(--docx-minorHAnsi-font);
        min-height: 12.00pt;
        font-size: 12.00pt;
    }

    p.docx_heading1 {
        margin-top: 18.00pt;
        margin-bottom: 4.00pt;
    }

    p.docx_heading1 span {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 20.00pt;
        font-size: 20.00pt;
    }

    p.docx_heading1 span {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 20.00pt;
        font-size: 20.00pt;
    }

    p.docx_heading2 {
        margin-top: 8.00pt;
        margin-bottom: 4.00pt;
    }

    p.docx_heading2 span {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 16.00pt;
        font-size: 16.00pt;
    }

    p.docx_heading2 span {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 16.00pt;
        font-size: 16.00pt;
    }

    p.docx_heading3 {
        margin-top: 8.00pt;
        margin-bottom: 4.00pt;
    }

    p.docx_heading3 span {
        color: #2F5496;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    p.docx_heading3 span {
        color: #2F5496;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    p.docx_heading4 {
        margin-top: 4.00pt;
        margin-bottom: 2.00pt;
    }

    p.docx_heading4 span {
        font-style: italic;
        color: #2F5496;
    }

    p.docx_heading4 span {
        font-style: italic;
        color: #2F5496;
    }

    p.docx_heading5 {
        margin-top: 4.00pt;
        margin-bottom: 2.00pt;
    }

    p.docx_heading5 span {
        color: #2F5496;
    }

    p.docx_heading5 span {
        color: #2F5496;
    }

    p.docx_heading6 {
        margin-top: 2.00pt;
    }

    p.docx_heading6 span {
        font-style: italic;
        color: #595959;
    }

    p.docx_heading6 span {
        font-style: italic;
        color: #595959;
    }

    p.docx_heading7 {
        margin-top: 2.00pt;
    }

    p.docx_heading7 span {
        color: #595959;
    }

    p.docx_heading7 span {
        color: #595959;
    }

    p.docx_heading8 {}

    p.docx_heading8 span {
        font-style: italic;
        color: #272727;
    }

    p.docx_heading8 span {
        font-style: italic;
        color: #272727;
    }

    p.docx_heading9 {}

    p.docx_heading9 span {
        color: #272727;
    }

    p.docx_heading9 span {
        color: #272727;
    }

    .docx table,
    table.docx_tablenormal td {
        padding-top: 0.00pt;
        padding-left: 5.40pt;
        padding-bottom: 0.00pt;
        padding-right: 5.40pt;
    }

    span.docx_heading1char {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 20.00pt;
        font-size: 20.00pt;
    }

    span.docx_heading1char p {
        margin-top: 18.00pt;
        margin-bottom: 4.00pt;
    }

    span.docx_heading1char {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 20.00pt;
        font-size: 20.00pt;
    }

    span.docx_heading2char {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 16.00pt;
        font-size: 16.00pt;
    }

    span.docx_heading2char p {
        margin-top: 8.00pt;
        margin-bottom: 4.00pt;
    }

    span.docx_heading2char {
        font-family: var(--docx-majorHAnsi-font);
        color: #2F5496;
        min-height: 16.00pt;
        font-size: 16.00pt;
    }

    span.docx_heading3char {
        color: #2F5496;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    span.docx_heading3char p {
        margin-top: 8.00pt;
        margin-bottom: 4.00pt;
    }

    span.docx_heading3char {
        color: #2F5496;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    span.docx_heading4char {
        font-style: italic;
        color: #2F5496;
    }

    span.docx_heading4char p {
        margin-top: 4.00pt;
        margin-bottom: 2.00pt;
    }

    span.docx_heading4char {
        font-style: italic;
        color: #2F5496;
    }

    span.docx_heading5char {
        color: #2F5496;
    }

    span.docx_heading5char p {
        margin-top: 4.00pt;
        margin-bottom: 2.00pt;
    }

    span.docx_heading5char {
        color: #2F5496;
    }

    span.docx_heading6char {
        font-style: italic;
        color: #595959;
    }

    span.docx_heading6char p {
        margin-top: 2.00pt;
    }

    span.docx_heading6char {
        font-style: italic;
        color: #595959;
    }

    span.docx_heading7char {
        color: #595959;
    }

    span.docx_heading7char p {
        margin-top: 2.00pt;
    }

    span.docx_heading7char {
        color: #595959;
    }

    span.docx_heading8char {
        font-style: italic;
        color: #272727;
    }

    span.docx_heading8char p {}

    span.docx_heading8char {
        font-style: italic;
        color: #272727;
    }

    span.docx_heading9char {
        color: #272727;
    }

    span.docx_heading9char p {}

    span.docx_heading9char {
        color: #272727;
    }

    p.docx_title {
        margin-bottom: 4.00pt;
    }

    p.docx_title span {
        font-family: var(--docx-majorHAnsi-font);
        min-height: 28.00pt;
        font-size: 28.00pt;
    }

    p.docx_title span {
        font-family: var(--docx-majorHAnsi-font);
        min-height: 28.00pt;
        font-size: 28.00pt;
    }

    span.docx_titlechar {
        font-family: var(--docx-majorHAnsi-font);
        min-height: 28.00pt;
        font-size: 28.00pt;
    }

    span.docx_titlechar p {
        margin-bottom: 4.00pt;
    }

    span.docx_titlechar {
        font-family: var(--docx-majorHAnsi-font);
        min-height: 28.00pt;
        font-size: 28.00pt;
    }

    p.docx_subtitle {
        margin-bottom: 8.00pt;
    }

    p.docx_subtitle span {
        color: #595959;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    p.docx_subtitle span {
        color: #595959;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    span.docx_subtitlechar {
        color: #595959;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    span.docx_subtitlechar p {
        margin-bottom: 8.00pt;
    }

    span.docx_subtitlechar {
        color: #595959;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    p.docx_quote {
        margin-top: 8.00pt;
        margin-bottom: 8.00pt;
        text-align: center;
    }

    p.docx_quote span {
        font-style: italic;
        color: #404040;
    }

    p.docx_quote span {
        font-style: italic;
        color: #404040;
    }

    span.docx_quotechar {
        font-style: italic;
        color: #404040;
    }

    span.docx_quotechar p {
        margin-top: 8.00pt;
        margin-bottom: 8.00pt;
        text-align: center;
    }

    span.docx_quotechar {
        font-style: italic;
        color: #404040;
    }

    p.docx_listparagraph {
        margin-left: 36.00pt;
    }

    span.docx_intenseemphasis {
        font-style: italic;
        color: #2F5496;
    }

    p.docx_intensequote {
        border-top: 0.50pt solid #2F5496;
        border-bottom: 0.50pt solid #2F5496;
        margin-top: 18.00pt;
        margin-bottom: 18.00pt;
        margin-left: 43.20pt;
        margin-right: 43.20pt;
        text-align: center;
    }

    p.docx_intensequote span {
        font-style: italic;
        color: #2F5496;
    }

    p.docx_intensequote span {
        font-style: italic;
        color: #2F5496;
    }

    span.docx_intensequotechar {
        font-style: italic;
        color: #2F5496;
    }

    span.docx_intensequotechar p {
        border-top: 0.50pt solid #2F5496;
        border-bottom: 0.50pt solid #2F5496;
        margin-top: 18.00pt;
        margin-bottom: 18.00pt;
        margin-left: 43.20pt;
        margin-right: 43.20pt;
        text-align: center;
    }

    span.docx_intensequotechar {
        font-style: italic;
        color: #2F5496;
    }

    span.docx_intensereference {
        font-weight: bold;
        text-transform: lowercase;
        color: #2F5496;
    }
</style><!--docxjs document numbering styles-->
<style>
    p.docx-num-1-0:before {
        content: "-\9";
        counter-increment: docx-num-1-0;
        font-family: Calibri;
    }

    p.docx-num-1-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-1-0 {
        counter-reset: docx-num-1-1;
    }

    p.docx-num-1-1:before {
        content: "o\9";
        counter-increment: docx-num-1-1;
        font-family: Courier New;
    }

    p.docx-num-1-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-1-1 {
        counter-reset: docx-num-1-2;
    }

    p.docx-num-1-2:before {
        content: "\9";
        counter-increment: docx-num-1-2;
        font-family: Wingdings;
    }

    p.docx-num-1-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-1-2 {
        counter-reset: docx-num-1-3;
    }

    p.docx-num-1-3:before {
        content: "\9";
        counter-increment: docx-num-1-3;
        font-family: Symbol;
    }

    p.docx-num-1-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-1-3 {
        counter-reset: docx-num-1-4;
    }

    p.docx-num-1-4:before {
        content: "o\9";
        counter-increment: docx-num-1-4;
        font-family: Courier New;
    }

    p.docx-num-1-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-1-4 {
        counter-reset: docx-num-1-5;
    }

    p.docx-num-1-5:before {
        content: "\9";
        counter-increment: docx-num-1-5;
        font-family: Wingdings;
    }

    p.docx-num-1-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-1-5 {
        counter-reset: docx-num-1-6;
    }

    p.docx-num-1-6:before {
        content: "\9";
        counter-increment: docx-num-1-6;
        font-family: Symbol;
    }

    p.docx-num-1-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-1-6 {
        counter-reset: docx-num-1-7;
    }

    p.docx-num-1-7:before {
        content: "o\9";
        counter-increment: docx-num-1-7;
        font-family: Courier New;
    }

    p.docx-num-1-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-1-7 {
        counter-reset: docx-num-1-8;
    }

    p.docx-num-1-8:before {
        content: "\9";
        counter-increment: docx-num-1-8;
        font-family: Wingdings;
    }

    p.docx-num-1-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 324.00pt;
    }

    .docx-wrapper {
        counter-reset: docx-num-1-0;
    }
</style>
<div class="docx-wrapper">
    <section class="docx" style="padding: 72pt; width: 612pt; min-height: 792pt;">
        <article>
            <p><span style="font-weight: bold; min-height: 16pt; font-size: 16pt;">Terms of Service</span></p>
            <p></p>
            <p><span>These Terms form an agreement between you and </span><span>Polybius</span><span>, L.L.C., a
                    Delaware company, and they include our Service Terms and important provisions for resolving disputes
                    through arbitration. By using our Services, you agree to these Terms.</span></p>
            <p></p>
            <p><span>These Terms of Use apply to your use of </span><span>Faster</span><span> other services for
                    individuals, along with any associated software applications and websites (all together,
                    “Services”). These Terms form an agreement between you and</span><span> Polybius</span><span>,
                    L.L.C., a Delaware company, and they include our Service Terms and important provisions for
                    resolving disputes through arbitration. By using our Services, you agree to these Terms. </span></p>
            <p></p>
            <p><span>If you reside in the European Economic Area, Switzerland, or the UK, your use of the Services is
                    governed by these terms</span><span>. </span></p>
            <p></p>
            <p><span>Our Privacy Policy explains how we collect and use personal information. Although it does not form
                    part of these Terms, it is an important document that you should read. </span></p>
            <p></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Registration and Access</span><span
                    style="min-height: 14pt; font-size: 14pt;"> </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Minimum Age.</span><span> You must be at least 13 years old or the
                    minimum age required in your country to consent to use the Services. If you are under 18 you must
                    have your parent or legal guardian’s permission to use the Services. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Registration.</span><span> You must provide accurate and complete
                    information to register for an account to use our Services. You may not share your account
                    credentials or make your account available to anyone else and are responsible for all activities
                    that occur under your account. If you create an account or use the Services on behalf of another
                    person or entity, you must have the authority to accept these Terms on their behalf. </span></p>
            <p></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Using Our Services </span></p>
            <p></p>
            <p><span style="font-weight: bold;">What You Can Do.</span><span> Subject to your compliance with these
                    Terms, you may access and use our Services. In using our Services, you must comply with all
                    applicable laws as well as our Sharing &amp; Publication Policy, Usage Policies, and any other
                    documentation, guidelines, or policies we make available to you. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">What You Cannot Do. </span></p>
            <p></p>
            <p><span>You may not use our Services for any illegal, harmful, or abusive activity. For example, you may
                    not: </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Use our Services in a way that infringes, misappropriates
                    or violates anyone’s rights. </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Modify, copy, lease, sell or distribute any of our
                    Services. </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Attempt to or assist anyone to reverse engineer, decompile
                    or discover the source code or underlying components of our Services, including our models,
                    algorithms, or systems (except to the extent this restriction is prohibited by applicable
                    law).</span></p>
            <p class="docx_listparagraph docx-num-1-0"><span> Automatically or programmatically extract data or Output
                    (defined below). </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Represent that Output was human-generated when it was not.
                </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Interfere with or disrupt our Services, including
                    circumvent any rate limits or restrictions or bypass any protective measures or safety mitigations
                    we put on our Services. </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Use Output to develop models that compete with</span><span>
                    Faster </span><span>.</span></p>
            <p></p>
            <p><span style="font-weight: bold;">Software.</span><span> Our Services may allow you to download software,
                    such as mobile applications, which may update automatically to ensure you’re using the latest
                    version. Our software may include open source software that is governed by its own licenses that
                    we’ve made available to you. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Corporate Domains.</span><span> If you create an account using an email
                    address owned by an organization (for example, your employer), that account may be added to the
                    organization's business account with us, in which case we will provide notice to you so that you can
                    help facilitate the transfer of your account (unless your organization has already provided notice
                    to you that it may monitor and control your account). Once your account is transferred, the
                    organization’s administrator will be able to control your account, including being able to access
                    Content (defined below) and restrict or remove your access to the account. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Third Party Services.</span><span> Our services may include third party
                    software, products, or services, (“Third Party Services”) and some parts of our Services, like our
                    browse feature, may include output from those services (“Third Party Output”). Third Party Services
                    and Third Party Output are subject to their own terms, and we are not responsible for them. </span>
            </p>
            <p></p>
            <p><span style="font-weight: bold;">Feedback.</span><span> We appreciate your feedback, and you agree that
                    we may use it without restriction or compensation to you. </span></p>
            <p></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Content </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Your Content.</span><span> You may provide input to the Services
                    (“Input”), and receive output from the Services based on the Input (“Output”). Input and Output are
                    collectively “Content.” You are responsible for Content, including ensuring that it does not violate
                    any applicable law or these Terms. You represent and warrant that you have all rights, licenses, and
                    permissions needed to provide Input to our Services. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Ownership of Content.</span><span> As between you and</span><span>
                    Polybius</span><span>, and to the extent permitted by applicable law, you (a) retain your ownership
                    rights in Input and (b) own the Output. We hereby assign to you all our right, title, and interest,
                    if any, in and to Output. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Similarity of Content.</span><span> Due to the nature of our Services
                    and artificial intelligence generally, output may not be unique and other users may receive similar
                    output from our Services. Our assignment above does not extend to other users’ output or any Third
                    Party Output. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Our Use of Content</span><span>. We may use Content to provide,
                    maintain, develop, and improve our Services, comply with applicable law, enforce our terms and
                    policies, and keep our Services safe. </span></p>
            <p><span style="font-weight: bold;">Opt Out</span><span>. If you do not want us to use your Content to train
                    our models, you can opt out by following the instructions in this Help Center article. Please note
                    that in some cases this may limit the ability of our Services to better address your specific use
                    case. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Accuracy.</span><span> Artificial intelligence and machine learning are
                    rapidly evolving fields of study. We are constantly working to improve our Services to make them
                    more accurate, reliable, safe, and beneficial. Given the probabilistic nature of machine learning,
                    use of our Services may, in some situations, result in Output that does not accurately reflect real
                    people, places, or facts. </span></p>
            <p></p>
            <p><span>When you use our Services you understand and agree: </span></p>
            <p></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Output may not always be accurate. You should not rely on
                    Output from our Services as a sole source of truth or factual information, or as a substitute for
                    professional advice. </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>You must evaluate Output for accuracy and appropriateness
                    for your use case, including using human review as appropriate, before using or sharing Output from
                    the Services. </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>You must not use any Output relating to a person for any
                    purpose that could have a legal or material impact on that person, such as making credit,
                    educational, employment, housing, insurance, legal, medical, or other important decisions about
                    them. </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Our Services may provide incomplete, incorrect, or
                    offensive Output that does not represent</span><span> Polybius</span><span>’s views. If Output
                    references any third party products or services, it doesn’t mean the third party endorses or is
                    affiliated with</span><span> Polybius.</span><span> </span></p>
            <p class="docx_listparagraph"></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Our IP Rights</span><span> </span>
            </p>
            <p><span>We and our affiliates own all rights, title, and interest in and to the Services. You may only use
                    our name and logo in accordance with our Brand Guidelines. </span></p>
            <p></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Paid Accounts</span><span> </span>
            </p>
            <p></p>
            <p><span style="font-weight: bold;">Billing.</span><span> If you purchase any Services, you will provide
                    complete and accurate billing information, including a valid payment method. For paid subscriptions,
                    we will automatically charge your payment method on each agreed-upon periodic renewal until you
                    cancel. You’re responsible for all applicable taxes, and we’ll charge tax when required. If your
                    payment cannot be completed, we may downgrade your account or suspend your access to our Services
                    until payment is received. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Service Credits.</span><span> You can pay for some Services in advance
                    by purchasing service credits. All service credits are subject to our Service Credit Terms. </span>
            </p>
            <p></p>
            <p><span style="font-weight: bold;">Cancellation.</span><span> You can cancel your paid subscription at any
                    time. Payments are non-refundable, except where required by law. These Terms do not override any
                    mandatory local laws regarding your cancellation rights. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Changes.</span><span> We may change our prices from time to time. If we
                    increase our subscription prices, we will give you at least 30 days’ notice and any price increase
                    will take effect on your next renewal so that you can cancel if you do not agree to the price
                    increase. </span></p>
            <p></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Termination and Suspension </span>
            </p>
            <p></p>
            <p><span style="font-weight: bold;">Termination.</span><span> You are free to stop using our Services at any
                    time. We reserve the right to suspend or terminate your access to our Services or delete your
                    account if we determine: </span></p>
            <p></p>
            <p class="docx_listparagraph docx-num-1-0"><span>You breached these Terms or our Usage Policies. </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>We must do so to comply with the law. </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Your use of our Services could cause risk or harm
                    to</span><span> Polybius</span><span>, our users, or anyone else.</span></p>
            <p></p>
            <p><span>We also may terminate your account if it has been inactive for over a year and you do not have a
                    paid account. If we do, we will provide you with advance notice. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Appeals.</span><span> If you believe we have suspended or terminated
                    your account in error, you can file an appeal with us by contacting our Support team. </span></p>
            <p></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Discontinuation of
                    Services</span><span style="min-height: 14pt; font-size: 14pt;"> </span></p>
            <p></p>
            <p><span>We may decide to discontinue our Services, but if we do, we will give you advance notice and a
                    refund for any prepaid, unused Services. </span></p>
            <p></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Disclaimer of Warranties </span></p>
            <p></p>
            <p><span>OUR SERVICES ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, WE AND OUR AFFILIATES
                    AND LICENSORS MAKE NO WARRANTIES (EXPRESS, IMPLIED, STATUTORY OR OTHERWISE) WITH RESPECT TO THE
                    SERVICES, AND DISCLAIM ALL WARRANTIES INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NON-INFRINGEMENT, AND QUIET ENJOYMENT, AND
                    ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR TRADE USAGE. WE DO NOT WARRANT THAT THE
                    SERVICES WILL BE UNINTERRUPTED, ACCURATE OR ERROR FREE, OR THAT ANY CONTENT WILL BE SECURE OR NOT
                    LOST OR ALTERED. YOU ACCEPT AND AGREE THAT ANY USE OF OUTPUTS FROM OUR SERVICE IS AT YOUR SOLE RISK
                    AND YOU WILL NOT RELY ON OUTPUT AS A SOLE SOURCE OF TRUTH OR FACTUAL INFORMATION, OR AS A SUBSTITUTE
                    FOR PROFESSIONAL ADVICE. </span></p>
            <p></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Limitation of Liability </span></p>
            <p></p>
            <p><span>NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                    SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE,
                    OR DATA OR OTHER LOSSES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR
                    AGGREGATE LIABILITY UNDER THESE TERMS WILL NOT EXCEED ​​THE GREATER OF THE AMOUNT YOU PAID FOR THE
                    SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE OR ONE HUNDRED
                    DOLLARS ($100). THE LIMITATIONS IN THIS SECTION APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED BY
                    APPLICABLE LAW. </span></p>
            <p></p>
            <p><span>Some countries and states do not allow the disclaimer of certain warranties or the limitation of
                    certain damages, so some or all of the terms above may not apply to you, and you may have additional
                    rights. In that case, these Terms only limit our responsibilities to the maximum extent permissible
                    in your country of residence. </span></p>
            <p></p>
            <p><span>POLYBIUS</span><span>’S AFFILIATES, SUPPLIERS, LICENSORS, AND DISTRIBUTORS ARE INTENDED THIRD PARTY
                    BENEFICIARIES OF THIS SECTION. </span></p>
            <p></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Indemnity </span></p>
            <p></p>
            <p><span>If you are a business or organization, to the extent permitted by law, you will indemnify and hold
                    harmless us, our affiliates, and our personnel, from and against any costs, losses, liabilities, and
                    expenses (including attorneys’ fees) from third party claims arising out of or relating to your use
                    of the Services and Content or any violation of these Terms. </span></p>
            <p></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Dispute Resolution</span><span
                    style="min-height: 14pt; font-size: 14pt;"> </span></p>
            <p></p>
            <p><span>YOU AND</span><span> POLYBIUS</span><span> AGREE TO THE FOLLOWING MANDATORY ARBITRATION AND CLASS
                    ACTION WAIVER PROVISIONS: </span></p>
            <p></p>
            <p><span style="font-weight: bold;">MANDATORY ARBITRATION</span><span>. You and</span><span>
                    Polybius</span><span> agree to resolve any claims arising out of or relating to these Terms or our
                    Services, regardless of when the claim arose, even if it was before these Terms existed (a
                    “Dispute”), through final and binding arbitration. You may opt out of arbitration within 30 days of
                    account creation or of any updates to these arbitration terms within 30 days after the update has
                    taken effect by filling out this form. If you opt out of an update, the last set of agreed upon
                    arbitration terms will apply.</span></p>
            <p></p>
            <p><span style="font-weight: bold;">Informal Dispute Resolution.</span><span> We would like to understand
                    and try to address your concerns prior to formal legal action. Before either of us files a claim
                    against the other, we both agree to try to resolve the Dispute informally. You agree to do so by
                    sending us notice through this form. We will do so by sending you notice to the email address
                    associated with your account. If we are unable to resolve a Dispute within 60 days, either of us has
                    the right to initiate arbitration. We also both agree to attend an individual settlement conference
                    if either party requests one </span><span>during this time. Any statute of limitations will be
                    tolled during this informal resolution process. Arbitration Forum. If we are unable to resolve the
                    Dispute, either of us may commence arbitration with National Arbitration and Mediation (“NAM”) under
                    its Comprehensive Dispute Resolution Rules and Procedures and/or Supplemental Rules for Mass
                    Arbitration Filings, as applicable (available here).</span><span> Polybius</span><span> will not
                    seek attorneys’ fees and costs in arbitration unless the arbitrator determines that your claim is
                    frivolous. The activities described in these Terms involve interstate commerce and the Federal
                    Arbitration Act will govern the interpretation and enforcement of these arbitration terms and any
                    arbitration. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Arbitration Procedures.</span><span> The arbitration will be conducted
                    by videoconference if possible, but if the arbitrator determines a hearing should be conducted in
                    person, the location will be mutually agreed upon, in the county where you reside, or as determined
                    by the arbitrator, unless the batch arbitration process applies. The arbitration will be conducted
                    by a sole arbitrator. The arbitrator will be either a retired judge or an attorney licensed to
                    practice law in the state of California. The arbitrator will have exclusive authority to resolve any
                    Dispute, except the state or federal courts of San Francisco, California have the authority to
                    determine any Dispute about enforceability, validity of the class action waiver, or requests for
                    public injunctive relief, as set out below. Any settlement offer amounts will not be disclosed to
                    the arbitrator by either party until after the arbitrator determines the final award, if any. The
                    arbitrator has the authority to grant motions dispositive of all or part of any Dispute. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Exceptions.</span><span> This section does not require informal dispute
                    resolution or arbitration of the following claims: (i) individual claims brought in small claims
                    court; and (ii) injunctive or other equitable relief to stop unauthorized use or abuse of the
                    Services or intellectual property infringement or misappropriation. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">CLASS AND JURY TRIAL WAIVERS</span><span>. You and</span><span>
                    Polybius</span><span> agree that Disputes must be brought on an individual basis only, and may not
                    be brought as a plaintiff or class member in any purported class, consolidated, or representative
                    proceeding. Class arbitrations, class actions, and representative actions are prohibited. Only
                    individual relief is available. The parties agree to sever and litigate in court any request for
                    public injunctive relief after completing arbitration for the underlying claim and all other claims.
                    This does not prevent either party from participating in a class-wide settlement. You
                    and</span><span> Polybius</span><span> knowingly and irrevocably waive any right to trial by jury in
                    any action, proceeding, or counterclaim. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Batch Arbitration</span><span>. If 25 or more claimants represented by
                    the same or similar counsel file demands for arbitration raising substantially similar Disputes
                    within 90 days of each other, then you and</span><span> Polybius</span><span> agree that NAM will
                    administer them in batches of up to 50 claimants each (“Batch”), unless there are less than 50
                    claimants in total or after batching, which will comprise a single Batch. NAM will administer each
                    Batch as a single consolidated arbitration with one arbitrator, one set of arbitration fees, and one
                    hearing held by videoconference or in a location decided by the arbitrator for each Batch. If any
                    part of this section is found to be invalid or unenforceable as to a particular claimant or Batch,
                    it will be severed and arbitrated in individual proceedings. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Severability.</span><span> If any part of these arbitration terms is
                    found to be illegal or unenforceable, the remainder will remain in effect, except that if a finding
                    of partial illegality or unenforceability would allow class arbitration, class action, or
                    representative action, this entire dispute resolution section will be unenforceable in its entirety.
                </span></p>
            <p></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">Copyright Complaints </span></p>
            <p><span>If you believe that your intellectual property rights have been infringed, please send notice to
                    the address below or fill out this form. We may delete or disable content that we believe violates
                    these Terms or is alleged to be infringing and will terminate accounts of repeat infringers where
                    appropriate. </span></p>
            <p></p>
            <p><span>Polybius</span><span>, L.L.C. </span></p>
            <p><span>18730 Lake Forest Drive</span></p>
            <p><span>Penn Valley, 95946, CA</span></p>
            <p><span>Attn: General Counsel / Copyright Agent </span></p>
            <p></p>
            <p><span>Written claims concerning copyright infringement must include the following information: </span>
            </p>
            <p class="docx_listparagraph docx-num-1-0"><span>A physical or electronic signature of the person authorized
                    to act on behalf of the owner of the copyright interest </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>A description of the copyrighted work that you claim has
                    been infringed upon </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>A description of where the allegedly infringing material is
                    located on our site so we can find it Your address, telephone number, and e-mail address </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>A statement by you that you have a good-faith belief that
                    the disputed use is not authorized by the copyright owner, its agent, or the law </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>A statement by you that the above information in your
                    notice is accurate and, under penalty of perjury, that you are the copyright owner or authorized to
                    act on the copyright owner’s behalf </span></p>
            <p></p>
            <p><span style="font-weight: bold; min-height: 14pt; font-size: 14pt;">General Terms </span></p>
            <p class="docx_listparagraph docx-num-1-0"></p>
            <p><span style="font-weight: bold;">Assignment.</span><span> You may not assign or transfer any rights or
                    obligations under these Terms and any attempt to do so will be void. We may assign our rights or
                    obligations under these Terms to any affiliate, subsidiary, or successor in interest of any business
                    associated with our Services. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Changes to These Terms or Our Services.</span><span> We are continuously
                    working to develop and improve our Services. We may update these Terms or our Services accordingly
                    from time to time. For example, we may make changes to these Terms or the Services due to: </span>
            </p>
            <p></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Changes to the law or regulatory requirements. </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Security or safety reasons. </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Circumstances beyond our reasonable control. </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>Changes we make in the usual course of developing our
                    Services. </span></p>
            <p class="docx_listparagraph docx-num-1-0"><span>To adapt to new technologies. </span></p>
            <p></p>
            <p><span>We will give you at least 30 days advance notice of changes to these Terms that materially
                    adversely impact you either via email or an in-product notification. All other changes will be
                    effective as soon as we post them to our website. If you do not agree to the changes, you must stop
                    using our Services. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Delay in Enforcing These Terms.</span><span> Our failure to enforce a
                    provision is not a waiver of our right to do so later. Except as provided in the dispute resolution
                    section above, if any portion of these Terms is determined to be invalid or unenforceable, that
                    portion will be enforced to the maximum extent permissible and it will not affect the enforceability
                    of any other terms. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Trade Controls.</span><span> You must comply with all applicable trade
                    laws, including sanctions and export control laws. Our Services may not be used in or for the
                    benefit of, or exported or re-exported to (a) any U.S. embargoed country or territory or (b) any
                    individual or entity with whom dealings are prohibited or restricted under applicable trade laws.
                    Our Services may not be used for any end use prohibited by applicable trade laws, and your Input may
                    not include material or information that requires a government license for release or export.
                </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Entire Agreement.</span><span> These Terms contain the entire agreement
                    between you and </span><span>Polybius </span><span>regarding the Services and, other than any
                    Service-specific terms, supersedes any prior or contemporaneous agreements between you
                    and</span><span> Polybius</span><span>. </span></p>
            <p></p>
            <p><span style="font-weight: bold;">Governing Law.</span><span> California law will govern these Terms
                    except for its conflicts of laws principles. Except as provided in the dispute resolution section
                    above, all claims arising out of or relating to these Terms will be brought exclusively in the
                    federal or state courts of San Francisco, California.</span></p>
        </article>
    </section>
</div>