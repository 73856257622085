import { Injectable, NgZone, isDevMode } from '@angular/core';
import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OpenReplayService {

  public tracker?: Tracker | null;

  constructor(private zone: NgZone) {
    if (window.location.hostname != 'localhost') { 
      this.zone.runOutsideAngular(() => {
        this.tracker = new Tracker({
          projectKey: environment.openReplayKey,
          obscureInputDates: false,
          obscureInputEmails: false,
          obscureInputNumbers: false,
          obscureTextEmails: false,
          obscureTextNumbers: false,
        });

        this.tracker.use(
          trackerAssist({
            confirmText: `You have an incoming call from Equity Edge Support. Do you want to answer?`,
          })
        );
      });
      // console.log("Openreplay active");
    }
  }

  public async start() {
    this.zone.runOutsideAngular(() => {
      if (this.tracker) {
        // console.log("Tracker Started");
        return this.tracker.start();
      } else {
        return {
          sessionID: null,
          sessionToken: null,
          userUUID: null,
        };
      }
    });
  }

  public setUserData(email: string): void {
    this.zone.runOutsideAngular(() => {
      if (this.tracker && email) {
        this.tracker.setUserID(String(email));
      }
    });
  }
}
