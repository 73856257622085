<button (click)="openSignUpModal()" *ngIf="user?.is_anonymous"
    class="inline-flex items-center rounded-full py-3 px-3 text-base font-semibold tracking-light text-gray-900 hover:bg-gray-50 hover:ring-1 hover:ring-inset hover:ring-gray-500/10 space-x-2 z-10"
    aria-haspopup="true">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
        <path
            d="M10 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3.465 14.493a1.23 1.23 0 0 0 .41 1.412A9.957 9.957 0 0 0 10 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 0 0-13.074.003Z" />
    </svg>
    <span>Sign up</span>
</button>

<button (click)="dropdownOpen = !dropdownOpen" (blur)="close()" *ngIf="!user?.is_anonymous">
    <img class="inline-block h-8 w-8 rounded-full" *ngIf="user?.user_metadata && user?.user_metadata?.['avatar_url']"
        [src]=" user?.user_metadata?.['avatar_url']" alt="">
    <span class="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100"
        *ngIf="user && user.email && !user?.user_metadata?.['avatar_url']">
        <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
    </span>
</button>

<div class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-out duration-100 transform"
    [class.opacity-0]="!dropdownOpen" [class.scale-95]="!dropdownOpen" [class.opacity-100]="dropdownOpen"
    [class.scale-100]="dropdownOpen" *ngIf="dropdownOpen" role="menu" aria-orientation="vertical"
    aria-labelledby="menu-button" tabindex="-1">
    <div class="text-gray-900 group flex items-center px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-6">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
            class="mr-3 h-4 w-4 text-gray-600">
            <path
                d="M10 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3.465 14.493a1.23 1.23 0 0 0 .41 1.412A9.957 9.957 0 0 0 10 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 0 0-13.074.003Z" />
        </svg>
        <span *ngIf="!user?.user_metadata?.['name']">{{ user?.email }}</span>
        <span *ngIf="user?.user_metadata?.['name']">{{ user?.user_metadata?.['name'] }}</span>
    </div>
   
    <button class="text-gray-700 group flex items-center px-4 py-2 text-sm hover:bg-gray-100 w-full" role="menuitem"
        tabindex="-1" id="menu-item-6">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
            class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500">
            <path fill-rule="evenodd"
                d="M17 4.25A2.25 2.25 0 0 0 14.75 2h-5.5A2.25 2.25 0 0 0 7 4.25v2a.75.75 0 0 0 1.5 0v-2a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 .75.75v11.5a.75.75 0 0 1-.75.75h-5.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 0-1.5 0v2A2.25 2.25 0 0 0 9.25 18h5.5A2.25 2.25 0 0 0 17 15.75V4.25Z"
                clip-rule="evenodd" />
            <path fill-rule="evenodd"
                d="M1 10a.75.75 0 0 1 .75-.75h9.546l-1.048-.943a.75.75 0 1 1 1.004-1.114l2.5 2.25a.75.75 0 0 1 0 1.114l-2.5 2.25a.75.75 0 1 1-1.004-1.114l1.048-.943H1.75A.75.75 0 0 1 1 10Z"
                clip-rule="evenodd" />
        </svg>
        Sign out
    </button>
</div>