import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NotificationsOutletComponent } from './outlets/notifications-outlet/notifications-outlet.component';
import { ModalOutletComponent } from './outlets/modal-outlet/modal-outlet.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ModalOutletComponent,
    NotificationsOutletComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'faster-chat';

  constructor() {
    
  }
}
