import { Injectable } from '@angular/core'
import {
  AuthChangeEvent,
  createClient,
  Session,
  SupabaseClient,
  User,
} from '@supabase/supabase-js'
import { catchError, from, fromEvent, map, Observable, of, ReplaySubject, switchMap, tap, timer } from 'rxjs'
import { OpenReplayService } from '../open-replay/open-replay.service'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SupabaseService {


  public supabase: SupabaseClient;

  public userId: string | null = null;
  public accessToken: string | null = null;
  public accessToken$: ReplaySubject<string | null> = new ReplaySubject<string | null>(1);

  public refreshTokenTimer$: Observable<number> = timer(0, 1000 * 60 * 5);

  constructor(private openReplayService: OpenReplayService) {
    this.openReplayService.start();

    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey, {
      auth: {
        autoRefreshToken: false
      }
    });

    this.init();
    this.startTokenRefresh();
    this.handleOnlineOfflineEvents();
    this.openReplayService.start();
  }

  public init() {
    this.supabase?.auth.onAuthStateChange((event, session) => {
      if (session) {

        if (!this.userId && session.user) {
          this.openReplayService.setUserData(session.user.email!);
        }

        this.accessToken = session.access_token;
        this.userId = session.user?.id;
        this.accessToken$.next(session.access_token);
      } else {
        if (this.userId) {
          this.supabase.auth.refreshSession().then(() => {
            console.log("Refreshed session")
          })
        }
        this.accessToken = null;
        this.userId = null;
        this.accessToken$.next(null);
        // Refresh the damn token

      }
    });
  }

  private startTokenRefresh() {
    this.refreshTokenTimer$.pipe(
      switchMap(() => this.refreshSession())
    ).subscribe();
  }

  private refreshSession(): Observable<Session | null> {
    return from(this.supabase.auth.getSession()).pipe(
      switchMap(({ data: { session } }) => {
        if (session) {
          return from(this.supabase.auth.refreshSession()).pipe(
            map(({ data }) => data.session),
            tap(refreshedSession => {
              if (refreshedSession) {
                console.log("Refreshed session");
              }
            }),
            catchError(() => of(null))
          );
        }
        return of(null);
      }),
      catchError(() => of(null))
    );
  }

  private handleOnlineOfflineEvents() {
    fromEvent(window, 'online').pipe(
      tap(() => console.log('Network connection restored')),
      switchMap(() => this.refreshSession())
    ).subscribe();

    fromEvent(window, 'offline').pipe(
      tap(() => console.log('Network connection lost'))
    ).subscribe();
  }

  public profile(user: User) {
    return this.supabase?.from('users')
      .select('firstName, lastName, email')
      .eq('id', user.id)
      .single()
  }

  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase?.auth.onAuthStateChange(callback)
  }

  public signIn(email: string, password: string) {
    return this.supabase?.auth.signInWithPassword({
      email: email,
      password: password,
    })
  }

  public signInWithGoogle(): void {
    this.supabase?.auth.signInWithOAuth({ provider: 'google', options: { redirectTo: window.location.origin + '/new' } })
  }

  public signUp(email: string, password: string) {
    return this.supabase?.auth.signUp({
      email: email,
      password: password
    })
  }

  signOut() {
    return this.supabase?.auth.signOut()
  }


  public get user$(): Observable<User | null> {
    return from(this.supabase?.auth.getUser()).pipe(
      map(response => response.data.user ? response.data.user : null),
    );
  }

  public get session$(): Observable<Session | null> {
    if (!this.supabase) { return of(null) };
    return from(this.supabase.auth.getSession()).pipe(
      map(response => response.data.session));
  }
}
