import { Injectable } from '@angular/core';
import { AbstractModalComponent, ModalOptions, ModalRef } from '../../modal-ref';
import { Observable, Subject } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  public modalRef: ModalRef<unknown> | undefined;
  public onModalOpen$: Subject<ModalRef<unknown>> = new Subject<ModalRef<unknown>>();

  constructor() { }

  ngOnDestroy(): void { }

  public open<T>(content: ComponentType<AbstractModalComponent>, options?: ModalOptions): ModalRef<T> {
    const modalRef: ModalRef<T> = new ModalRef<T>(content, options);
    this.onModalOpen$.next(modalRef);
    return modalRef;
  }

  public close(data?: any): Observable<void> | undefined {
    if (!this.modalRef) return undefined;
    this.modalRef.close(data);
    return this.modalRef.onClose$;
  }

}
