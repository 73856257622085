import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, isDevMode } from '@angular/core';
import { CreateMessageInput, Message, MessageService } from '../../../services/message/message.service';
import { Observable, Subject, debounceTime, filter, map, switchMap, takeUntil } from 'rxjs';
import { PromptEditorComponent } from '../components/prompt-editor/prompt-editor.component';
import { PromptSuggestionsComponent } from '../components/prompt-suggestions/prompt-suggestions.component';
import { MessageComponent } from '../components/message/message.component';
import { v4 as uuidv4 } from 'uuid';
import { TippyDirective } from '@ngneat/helipopper';
import { NgxTurnstileModule } from "ngx-turnstile";
import { ModelSelectionDropdownComponent } from '../components/model-selection-dropdown/model-selection-dropdown.component';
import { FormsModule } from '@angular/forms';
import { SupabaseService } from '../../../services/supabase/supabase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Session, SessionGroup, SessionService } from '../../../services/session/session.service';
import { SessionAuthComponent } from '../components/session-auth/session-auth.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { User } from '@supabase/supabase-js';
import { SignUpModalComponent } from '../../../modals/sign-up-modal/sign-up-modal.component';
import { ModalService } from '../../../services/modal/modal.service';
import { UpgradeModalComponent } from '../../../modals/upgrade-modal/upgrade-modal.component';

@Component({
  selector: 'app-session',
  standalone: true,
  imports: [
    CommonModule,
    TippyDirective,
    NgxTurnstileModule,
    FormsModule,
    PromptEditorComponent,
    PromptSuggestionsComponent,
    MessageComponent,
    ModelSelectionDropdownComponent,
    SessionAuthComponent,
    SidebarComponent
  ],
  templateUrl: './session.component.html',
})
export class SessionComponent implements OnInit, AfterViewInit {

  @ViewChild(PromptEditorComponent) public promptEditor: PromptEditorComponent | undefined;
  @ViewChild('scrollContainer') scrollContainer: ElementRef | undefined

  public sessionId: string = this.route.snapshot.params['sessionId'];
  public sessionId$: Observable<string> = this.route.params.pipe(map(params => params['sessionId']));

  public destroyed$: Subject<void> = new Subject<void>();

  public processing: boolean = false;

  public model: 'llama3-8b-8192' | 'llama3-70b-8192' = 'llama3-70b-8192';
  public user: User | null = null;
  public session: Session | undefined;
  public messages: Message[] = [];
  public completion: Message | undefined;

  public sessionGroups: SessionGroup[] | undefined;
  public sidebarOpen: boolean = false;
  public firstTokenArrived: boolean = false;

  public scrolling: boolean = false;
  public rateLimitedUntil: Date | null = null;

  constructor(private cdr: ChangeDetectorRef,
    private supabase: SupabaseService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private modalService: ModalService,
    private sessionService: SessionService) {

    this.sidebarOpen = localStorage.getItem('sidebarState') === 'open';
  }

  ngOnInit(): void {
    this._getUser();
    this._loadMessages();
    this._loadSession();
    this._loadSessionGroups();
    this.subscribeToSessionId();
    this._verifyAuthentication();
  }

  ngAfterViewInit(): void {
    this.focus();
  }

  private _checkRateLimit(): void {
    if (this.user?.app_metadata['rateLimitedUntil']) {
      const rateLimitedUntil = new Date(this.user.app_metadata['rateLimitedUntil']);
      if (rateLimitedUntil > new Date()) {
        this.rateLimitedUntil = rateLimitedUntil;
        this.cdr.detectChanges();
      } else {
        this.rateLimitedUntil = null;
        this.cdr.detectChanges();
      }
    }
  }

  private _getUser(): void {
    this.supabase.user$.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      this.user = user;
      this._checkRateLimit();
      this.cdr.detectChanges();
    })
  }

  private _verifyAuthentication(): void {
    this.supabase.user$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe({
      next: (user: User | null) => {
        if (user == null) {
          this.router.navigate(['/authenticate']);
        } 
      }
    });
  }

  private _loadSession(): void {
    this.sessionId$.pipe(
      takeUntil(this.destroyed$),
      filter(sessionId => sessionId != null && sessionId != 'new'),
      filter(_ => !this.processing),
      switchMap(sessionId => this.sessionService.getSession(sessionId))).subscribe({
        next: (session: Session) => {
          this.session = session;
          this.cdr.detectChanges();
        },
        error: (error: any) => {
          console.log("Failed to load session", error);
        }
      })
  }

  private _loadMessages(): void {
    this.sessionId$.pipe(
      takeUntil(this.destroyed$),
      filter(sessionId => sessionId != null && sessionId != 'new'),
      filter(_ => !this.processing),
      switchMap(sessionId => this.messageService.loadMessages(sessionId))).subscribe({
        next: (messages: Message[]) => {
          this.messages = messages;
          this.cdr.detectChanges();
          this.scrollToBottom();
        },
        error: (error: any) => {
          console.log("Failed to load messages", error);
        }
      })
  }

  private subscribeToSessionId(): void {
    this.sessionId$.pipe(
      takeUntil(this.destroyed$),
      filter(_ => !this.processing),
    ).subscribe((sessionId) => {
      this.sessionId = sessionId;

      if (sessionId == 'new') {
        this.messages = [];
        this.completion = undefined;
        this.cdr.detectChanges();
        this.focus();
      }

      this.cdr.detectChanges();
    })
  }

  private _loadSessionGroups(): void {
    this.sessionService.loadGroupedSession().pipe(takeUntil(this.destroyed$)).subscribe(sessionGroups => {
      this.sessionGroups = sessionGroups;
      this.cdr.detectChanges();
    });
  }

  public focus(): void {
    this.promptEditor?.focus();
  }


  public createNewSession(): void {
    this.processing = false;
    this.messages = [];
    this.sessionId = 'new';
    this.router.navigate([`/${this.sessionId}`]);
    this.cdr.detectChanges();
    this.focus();
  }

  public stream(prompt: string): void {
    var newSession: boolean = false;

    if (!this.supabase.userId) {
      return;
    }

    if (this.user?.is_anonymous && this.user.app_metadata['numCompletions'] > 10) {
      this.modalService.open(SignUpModalComponent);
      return;
    }

    if (this.rateLimitedUntil) {
      this.modalService.open(UpgradeModalComponent);
      return;
    }

    if (this.sessionId == 'new') {
      this.sessionId = uuidv4();
      this.router.navigate([`/${this.sessionId}`]);
      newSession = true;
    }

    this.processing = true;
    this.scrolling = false;
    this.firstTokenArrived = false;

    const message: Message = {
      id: uuidv4(),
      date: this.messageService.getCurrentUtcTimestamp(),
      role: 'User',
      status: 'Completed',
      content: prompt,
      session_id: this.sessionId,
      user_id: this.supabase.userId,
      created_at: this.messageService.getCurrentUtcTimestamp(),
      updated_at: this.messageService.getCurrentUtcTimestamp()
    }

    this.messages.push(message);
    this.cdr.detectChanges();
    this.scrollToBottom();

    this.messageService.stream(this.messages, this.sessionId, this.model).pipe(
    ).subscribe({
      next: (message: Message) => {
        if (!this.firstTokenArrived) {
          this.scrollToBottom();
        }
        this.firstTokenArrived = true;

        this.completion = message;
        this._updateMessages(message);

        if (!this.scrolling) {
          this.scrollToBottom();
        }


        this.cdr.detectChanges();
      },
      error: (error: any) => {
        console.log("Failed to stream messages", error);
        this.processing = false;
        this.completion = undefined;

        // Log message as failure.
        this.cdr.detectChanges();
      },

      complete: () => {
        this.processing = false;
        this.focus();
        if (this.completion) {
          this.createMessage(this.completion)
        }

        // Add step to rename session after first completion 
        this.completion = undefined;

        console.log("Stream complete");

        if (this.session && this.session.name == null) {
          this.nameSession();
        }

        this._getUser();
        this.cdr.detectChanges();
      }
    })

    if (newSession) {
      this.createSessionAndMessage(message);
    } else {
      this.createMessage(message);
      this._updateSessionTimestamp();
    }

  }

  isUserAtBottom(): boolean {
    if (!this.scrollContainer) {
      return false;
    }
    const nativeElement = this.scrollContainer.nativeElement;
    return (nativeElement.scrollTop + nativeElement.clientHeight + 1 >= nativeElement.scrollHeight);
  }

  scrollToBottom(): void {
    if (!this.scrollContainer) {
      return;
    }

    try {
      const element = this.scrollContainer.nativeElement;
      const scrollOptions: ScrollToOptions = {
        top: element.scrollHeight,
        left: 0,
        behavior: 'smooth'
      };

      element.scrollTo(scrollOptions);
    } catch (err) {
      console.error('Could not scroll to bottom: ', err);
    }
  }


  private _updateMessages(message: Message): void {
    const messageIndex = this.messages.findIndex(m => m.id === message?.id);
    if (messageIndex !== -1) {
      // this.messages[messageIndex]['content'] = message.content;
      this.messages[messageIndex] = message;
    } else {
      this.messages.push(message!);
    }
  }

  public trackMessage(index: number, message: Message): string {
    return message.id.toString();
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    this.scrolling = true;
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.scrolling = true;
  }

  public createMessage(message: CreateMessageInput): void {
    this.messageService.createMessage(message).subscribe({
      next: (message: Message) => {
      },
      error: (error: any) => {
        console.log("Failed to create message", error);
      }
    });
  }

  public createSessionAndMessage(message: CreateMessageInput): void {
    this.messageService.createMessageInNewSession(message).subscribe({
      next: (response: { 'session': Session, 'message': Message }) => {
        this.session = response.session;
        this._addToSessionGroup(response.session);
      },
      error: (error: any) => {
        console.log("Failed to create session and message", error);
      }
    });
  }

  public toggleSidebar(): void {
    this.sidebarOpen = !this.sidebarOpen;
    localStorage.setItem('sidebarState', this.sidebarOpen ? 'open' : 'closed');
    this.cdr.detectChanges();
  }

  public nameSession(): void {
    this.sessionService.nameSession(this.messages, this.sessionId).pipe(takeUntil(this.destroyed$)).subscribe((session: Session) => {
      this.session = session;
      this._updateSessionGroups(session);

      this.cdr.detectChanges();
    })
  }

  private _addToSessionGroup(session: Session): void {
    if (!this.sessionGroups) {
      return;
    }

    this.sessionGroups[0].sessions.unshift(session);

    this.cdr.detectChanges();
  }

  private _updateSessionGroups(session: Session): void {
    if (!this.sessionGroups) {
      return;
    }

    this.sessionGroups = this.sessionGroups.map(group => {
      group.sessions = group.sessions.map(s => {
        if (s.id === session.id) {
          return session;
        }
        return s;
      })
      return group;
    })
  }

  private _updateSessionTimestamp(): void {
    this.sessionService.updateSession({ id: this.sessionId, last_message: this.messageService.getCurrentUtcTimestamp() }).pipe(takeUntil(this.destroyed$)).subscribe((session: Session) => {
      this.session = session;
      this._updateSessionGroups(session);
      this.cdr.detectChanges();
    })
  }

}
