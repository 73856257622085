import { Injectable } from '@angular/core';
import { SupabaseService } from '../supabase/supabase.service';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TurnstileService {

  constructor(private supabase: SupabaseService) { }

  public validateTurnstileToken(token: string | null): Observable<any> {
    return from(this.supabase.supabase.functions.invoke('verify_turnstile_token', { body: { token } }))
  }
}
