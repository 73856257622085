import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { SupabaseService } from '../../../services/supabase/supabase.service';
import { NgxTurnstileModule } from 'ngx-turnstile';
import { environment } from '../../../../environments/environment';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { AuthResponse, AuthTokenResponsePassword } from '@supabase/supabase-js';
import { FadeInAnimation } from '../../../animations';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxTurnstileModule
  ],
  templateUrl: './sign-up.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeInAnimation]
})
export class SignUpComponent implements AfterViewInit {

  public sitekey = environment.turnstileSitekey;
  public turnstileToken: string | null = window.location.origin.includes('localhost') ? 'test' : null;

  constructor(private supabase: SupabaseService, private router: Router, private notifications: NotificationsService, private cdr: ChangeDetectorRef) {

  }

  ngAfterViewInit(): void {
    this.supabase.user$.subscribe(user => {
      if (user && !user.is_anonymous) {
        this.router.navigate(['/']);
      }
    })
  }

  public signUpForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, passwordLengthValidator(), passwordHasLowercaseValidator(), passwordHasUppercaseValidator(), passwordHasDigitValidator()])
  });

  public signUp(): void {
    if (!this.turnstileToken) {
      return;
    }
    const email = this.signUpForm.get('email')?.value;
    const password = this.signUpForm.get('password')?.value;

    this.supabase.supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        captchaToken: this.turnstileToken,
        emailRedirectTo: window.location.origin
      }
    }).then((response: AuthResponse) => {
      if (response.error) {
        this.notifications.error('Error', response.error.message);
      } else {
        this.notifications.success('Success', 'A verification email has been sent to your email address');
      }
    })
  }

  public signUpWithGoogle(): void {
    this.supabase.signInWithGoogle()
  }

  public setTurnstileToken(turnstileToken: string | null): void {
    this.turnstileToken = turnstileToken;
  }

}

export function passwordLengthValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const password = control.value;
    if (password && password.length >= 8) {
      return null; // valid password length
    } else {
      return { 'passwordTooShort': true }; // invalid password length
    }
  };
}

export function passwordHasDigitValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const password = control.value;
    if (password && /\d/.test(password)) {
      return null; // password has at least one digit
    } else {
      return { 'passwordNoDigit': true }; // password has no digit
    }
  };
}

export function passwordHasUppercaseValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const password = control.value;
    if (password && /[A-Z]/.test(password)) {
      return null; // password has at least one uppercase letter
    } else {
      return { 'passwordNoUppercase': true }; // password has no uppercase letter
    }
  };
}

export function passwordHasLowercaseValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const password = control.value;
    if (password && /[a-z]/.test(password)) {
      return null; // password has at least one lowercase letter
    } else {
      return { 'passwordNoLowercase': true }; // password has no lowercase letter
    }
  };
}