import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Notification, NotificationsService } from '../../services/notifications/notifications.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'notifications-outlet',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './notifications-outlet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsOutletComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<void> = new Subject<void>();
  public notification: Notification | undefined;

  constructor(private cdr: ChangeDetectorRef, private notificationService: NotificationsService) {

  }

  ngOnInit(): void {
    this._subscribeToNotifications();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private _subscribeToNotifications(): void {
    this.notificationService.notifications.pipe(takeUntil(this.destroyed$)).subscribe(notification => {
      this.notification = notification;

      // Close the notification after 5 seconds
      setTimeout(() => {
        this.notification = undefined;
        this.cdr.detectChanges();
      }, 5000);
      this.cdr.detectChanges();
    })
  }

  closeNotification(): void {
    this.notification = undefined;
    this.cdr.detectChanges();
  }

}
