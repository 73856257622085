<aside
    class="fixed inset-y-0 left-0 transform z-10 transition-transform duration-300 ease-in-out min-w-[16rem] max-w-[16rem] will-change-transform h-svh"
    [ngClass]="{'translate-x-0': sidebarOpen, '-translate-x-full': !sidebarOpen}">
    <sidebar [user]="user" [sessionGroups]="sessionGroups" (toggleSidebar)="toggleSidebar()"></sidebar>
</aside>

<main class="relative mx-auto transition-margin border-l border-gray-200 duration-300 ease-in-out"
    [ngStyle]="{'margin-left': sidebarOpen ? '16rem' : '0'}">
    <ng-container [ngTemplateOutlet]="newSession" *ngIf="sessionId == 'new'"></ng-container>
    <ng-container [ngTemplateOutlet]="session" *ngIf="sessionId != 'new'"></ng-container>

    <div class="absolute top-3 left-2" *ngIf="sessionId == 'new'">
        <model-selection-dropdown [(model)]="model"></model-selection-dropdown>
    </div>
    <div class="absolute top-3 right-5 z-10">
        <session-auth [user]="user"></session-auth>
    </div>

    <button class="absolute left-1 top-[45%] hidden sm:block" tp="Open Sidebar" (click)="toggleSidebar()" *ngIf="!sidebarOpen">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
            class="h-10 w-auto text-gray-300 hover:text-gray-600">
            <path d="M15.6315 12L10.8838 3.03212L9.11622 3.9679L13.3685 12L9.11622 20.0321L10.8838 20.9679L15.6315 12Z">
            </path>
        </svg>
    </button>

    <div class="absolute top-3 left-2 flex justify-start items-center" *ngIf="sessionId != 'new' && !sidebarOpen">
        <button (click)="createNewSession()" tp="New Session"
            class="inline-flex items-center rounded-full hover:bg-gray-50 py-1 px-2 text-xl font-semibold tracking-light text-gray-900 hover:ring-1 ring-inset ring-gray-500/10">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                class="w-7 h-7 text-yellow-500">
                <path fill-rule="evenodd"
                    d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z"
                    clip-rule="evenodd" />
            </svg> -->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                class="w-6 h-6 text-gray-700">
                <path
                    d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                <path
                    d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
            </svg>
        </button>
    </div>

</main>

<ng-template #newSession>
    <div class="flex flex-col items-start w-full pt-36 overflow-y-auto h-svh">
        <div class="mx-auto max-w-3xl px-6 sm:px-0">
            <h2 class="mt-6 pb-3 text-2xl sm:text-3xl font-extrabold whitespace-normal text-black pl-3 sm:w-[32rem]">
                What would you like to do?
            </h2>
            <!-- <div class="flex justify-center">
                <button class="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                    <span
                        class="">Llama
                        8B</span>
                    <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clip-rule="evenodd" />
                    </svg>
                </button>
            </div> -->

            <div class="relative mt-12 pb-12">
                <prompt-editor [user]="user" [sessionId]="sessionId" [disabled]="processing" (onSubmit)="stream($event)"
                    class="w-full"></prompt-editor>
                <prompt-suggestions (onClick)="stream($event)"></prompt-suggestions>
                <!-- <div class="absolute top-[-20px] left-0 relative inline-block text-left flex justify-center space-x-2">
                    
                    <span class="inline-flex items-center rounded-full  py-1 text-xs font-medium text-gray-600">Llama 7B</span>
                    <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clip-rule="evenodd" />
                    </svg>
                </div> -->
            </div>
        </div>
    </div>
</ng-template>

<ng-template #session>
    <div class="relative">
        <div class="flex flex-col items-center w-full overflow-y-auto h-svh px-4 sm:px-0" #scrollContainer>
            <div class="flex-1 mx-auto max-w-6xl">
                <div class="w-full py-6"></div>
                <message [message]="message" [terminal]="i == messages.length - 1" [processing]="processing"
                    *ngFor="let message of messages; let i = index; trackBy: trackMessage ">
                </message>
                <ng-container *ngIf="processing && !firstTokenArrived" [ngTemplateOutlet]="spinner"></ng-container>
                <div class="w-full py-16"></div>
            </div>
            <div class="sticky bottom-0 w-full z-10">
                <div class="mx-auto max-w-4xl rounded-md py-3 bg-gradient-to-t from-white from-90% from white">
                    <prompt-editor [user]="user" [sessionId]="sessionId" [disabled]="processing"
                        (onSubmit)="stream($event)"></prompt-editor>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #spinner>
    <div class="flex h-16 shrink-0 items-center group-hover:hidden">
        <svg aria-hidden="true" class="w-7 h-7 mr-3 text-yellow-400 animate-spin dark:text-black fill-white"
            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
            <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
        </svg>
    </div>
</ng-template>