import { HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, isDevMode } from '@angular/core';
import { ReplaySessionService } from '../../services/replay-session/replay-session.service';
import { filter, map } from 'rxjs';

export const openReplayInterceptor: HttpInterceptorFn = (req, next) => {

  const replaySessionService = inject(ReplaySessionService);

  if (isDevMode()) {
    return next(req);
  }

  const handleResponse = (request: HttpRequest<any>, response: HttpResponse<any>, event: string) => {
    // Forward data to the service, which will create the custom event and send it
    replaySessionService.sendEventToReplaySession(event, { request, response });
  };

  return next(req).pipe(
    filter((event: any) => event instanceof HttpResponse),
    map((resp: HttpResponse<any>) => {
      handleResponse(req, resp, `${req.url}`);
      return resp;
    })
  );
};
