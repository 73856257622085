import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Message } from '../../../../services/message/message.service';
import { CommonModule } from '@angular/common';
import { MarkdownModule, MarkdownService } from 'ngx-markdown';
import { FadeInAnimation } from '../../../../animations';
import { TippyDirective } from '@ngneat/helipopper';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ClipboardService } from 'ngx-clipboard';
import hljs from 'highlight.js';
import DOMPurify from 'dompurify';
import { marked } from 'marked';

@Component({
  selector: 'message',
  standalone: true,
  imports: [
    CommonModule,
    MarkdownModule,
    TippyDirective,
    ClipboardModule
  ],
  templateUrl: './message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeInAnimation],
  encapsulation: ViewEncapsulation.None
})
export class MessageComponent implements OnChanges {

  @Input() message: Message | undefined;
  @Input() processing: boolean = false;
  @Input() terminal: boolean = false;
  @Output() onRegenerate: EventEmitter<void> = new EventEmitter<void>();

  public highlighted: boolean = false;

  constructor(private cdr: ChangeDetectorRef, private elementRef: ElementRef, private clipboardService: ClipboardService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    // TODO: How do I highlight code when processing changes from true to false, and markdown emits a (ready) event?
  }

  public copied: boolean = false;
  public copy(): void {
    if (!this.message) return;

    this.copyFormattedText()
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
      this.cdr.detectChanges();
    }, 2000);
  }


  // Replaces the icon & text briefly after the user copies a code snippet
  public handleButtonClick(event: MouseEvent): void {
    const element = event.target as HTMLElement;
    const buttonId = this.getElementId(element);

    if (!buttonId) return;

    const button = document.getElementById(buttonId);
    if (!button) return;

    const svgIcon = button.querySelector('#svg-icon');
    if (!svgIcon) return;

    svgIcon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 text-white"><path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd" />';

    const text = button.querySelector('#text');
    if (!text) return;

    text.innerHTML = 'Copied';

    setTimeout(() => {
      text.innerHTML = 'Copy Code';
      svgIcon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 text-white"><path fill-rule="evenodd" d="M13.887 3.182c.396.037.79.08 1.183.128C16.194 3.45 17 4.414 17 5.517V16.75A2.25 2.25 0 0 1 14.75 19h-9.5A2.25 2.25 0 0 1 3 16.75V5.517c0-1.103.806-2.068 1.93-2.207.393-.048.787-.09 1.183-.128A3.001 3.001 0 0 1 9 1h2c1.373 0 2.531.923 2.887 2.182ZM7.5 4A1.5 1.5 0 0 1 9 2.5h2A1.5 1.5 0 0 1 12.5 4v.5h-5V4Z" clip-rule="evenodd" />';
      this.cdr.detectChanges();
    }, 1000);
  }

  getElementId(element: HTMLElement): string | null {
    if (element.parentElement?.id && element.parentElement?.id !== 'svg-icon') {
      return element.parentElement?.id;
    }

    if (element.parentElement?.parentElement?.id) {
      return element.parentElement?.parentElement?.id;
    }

    if (element.id) {
      return element.id;
    }

    return null;
  }

  public highlightCode(): void {
    // Run inside a damn webworker or via an external API
    // Cache highlighted code in localstorage & load directly from their via code HASH.
    if (this.terminal && this.processing) {
      return;
    }

    const codeElements = document.querySelectorAll('pre > code');
    for (let i = 0; i < codeElements.length; i++) {
      hljs.highlightElement(codeElements[i] as HTMLElement);
    }
  }

  private copyFormattedText() {
    if (!this.message?.content) { return };
    const html = marked(this.message.content).toString();
    const cleanHtml = DOMPurify.sanitize(html);
    const blob = new Blob([cleanHtml], { type: 'text/html' });
    navigator.clipboard.write([new ClipboardItem({ 'text/html': blob })]).then(() => {
      console.log('Rich text copied to clipboard');
    }).catch((error) => {
      console.error('Error copying rich text to clipboard:', error);
    });
  }

  public onCopy(event: ClipboardEvent): void {
    const content = event.clipboardData?.getData('text/html');
    if (!content) return;
    const sanitizedHtml = DOMPurify.sanitize(content);
    const plainText = sanitizedHtml.replace(/<[^>]+>/g, ''); // Remove HTML tags
    event.clipboardData?.setData('text', plainText); // Set plain text data
    event.clipboardData?.setData('text/html', sanitizedHtml); // Set sanitized HTML data
    event.preventDefault();
    event.stopPropagation();
  }

}
