<div class="relative h-svh pt-3">
    <a routerLink="/new"
        class="inline-flex items-center rounded-md py-2 px-4 text-base font-semibold tracking-light text-gray-900 hover:ring-1 ring-inset ring-gray-500/10 w-48 space-x-2 truncate">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-gray-700">
            <path
                d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
            <path
                d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
        </svg>
        <span class="font-base text-sm">New Session</span>
    </a>
    <div class="overflow-y-auto h-full px-2">
        <div *ngFor="let sessionGroup of sessionGroups" class="mt-4">
            <span class="text-xs font-light text-gray-400 px-2">{{ sessionGroup.date }}</span>
            <div *ngFor="let session of sessionGroup.sessions; let i = index; trackBy: trackSession;" class="m-1">
                <a [routerLink]="['/', session.id]"
                    class="inline-flex items-center rounded-full hover:bg-gray-50 py-2 px-4 text-sm font-semibold tracking-light text-gray-900 hover:ring-1 ring-inset ring-gray-500/10 min-w-full "
                    routerLinkActive="bg-gray-100/50 ring-1 ring-inset ring-gray-500/10">
                    <span *ngIf="session.name" class="min-w-48 max-w-48 truncate">{{ session.name}}</span>
                    <span *ngIf="!session.name">New Session</span>
                </a>
            </div>
        </div>
        <button class="absolute top-3 right-3 rounded-md p-2 hover:bg-gray-100" tp="Close Sidebar"
            (click)="toggleSidebar.emit()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                <path fill-rule="evenodd"
                    d="M4.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L6.31 10l3.72-3.72a.75.75 0 1 0-1.06-1.06L4.72 9.47Zm9.25-4.25L9.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L11.31 10l3.72-3.72a.75.75 0 0 0-1.06-1.06Z"
                    clip-rule="evenodd" />
            </svg>
        </button>
    </div>
    <button (click)="openUpgradeModal()" *ngIf="!user?.app_metadata?.['plan']"
        class="sticky bottom-0 inline-flex items-center py-4 px-6 text-base font-semibold tracking-light text-gray-900 w-full border-t border-gray-200 space-x-2 bg-white hover:bg-gray-50">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-blue-700">
            <path
                d="M15.98 1.804a1 1 0 0 0-1.96 0l-.24 1.192a1 1 0 0 1-.784.785l-1.192.238a1 1 0 0 0 0 1.962l1.192.238a1 1 0 0 1 .785.785l.238 1.192a1 1 0 0 0 1.962 0l.238-1.192a1 1 0 0 1 .785-.785l1.192-.238a1 1 0 0 0 0-1.962l-1.192-.238a1 1 0 0 1-.785-.785l-.238-1.192ZM6.949 5.684a1 1 0 0 0-1.898 0l-.683 2.051a1 1 0 0 1-.633.633l-2.051.683a1 1 0 0 0 0 1.898l2.051.684a1 1 0 0 1 .633.632l.683 2.051a1 1 0 0 0 1.898 0l.683-2.051a1 1 0 0 1 .633-.633l2.051-.683a1 1 0 0 0 0-1.898l-2.051-.683a1 1 0 0 1-.633-.633L6.95 5.684ZM13.949 13.684a1 1 0 0 0-1.898 0l-.184.551a1 1 0 0 1-.632.633l-.551.183a1 1 0 0 0 0 1.898l.551.183a1 1 0 0 1 .633.633l.183.551a1 1 0 0 0 1.898 0l.184-.551a1 1 0 0 1 .632-.633l.551-.183a1 1 0 0 0 0-1.898l-.551-.184a1 1 0 0 1-.633-.632l-.183-.551Z" />
        </svg>
        <span class="font-base text-sm">Upgrade to Pro</span>
    </button>
    <button (click)="openCustomerPortal()" *ngIf="user?.app_metadata?.['plan']"
        class="sticky bottom-0 inline-flex items-center py-4 px-6 text-base font-semibold tracking-light text-gray-900 w-full border-t border-gray-200 space-x-2 bg-white hover:bg-gray-50">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-blue-700">
            <path
                d="M15.98 1.804a1 1 0 0 0-1.96 0l-.24 1.192a1 1 0 0 1-.784.785l-1.192.238a1 1 0 0 0 0 1.962l1.192.238a1 1 0 0 1 .785.785l.238 1.192a1 1 0 0 0 1.962 0l.238-1.192a1 1 0 0 1 .785-.785l1.192-.238a1 1 0 0 0 0-1.962l-1.192-.238a1 1 0 0 1-.785-.785l-.238-1.192ZM6.949 5.684a1 1 0 0 0-1.898 0l-.683 2.051a1 1 0 0 1-.633.633l-2.051.683a1 1 0 0 0 0 1.898l2.051.684a1 1 0 0 1 .633.632l.683 2.051a1 1 0 0 0 1.898 0l.683-2.051a1 1 0 0 1 .633-.633l2.051-.683a1 1 0 0 0 0-1.898l-2.051-.683a1 1 0 0 1-.633-.633L6.95 5.684ZM13.949 13.684a1 1 0 0 0-1.898 0l-.184.551a1 1 0 0 1-.632.633l-.551.183a1 1 0 0 0 0 1.898l.551.183a1 1 0 0 1 .633.633l.183.551a1 1 0 0 0 1.898 0l.184-.551a1 1 0 0 1 .632-.633l.551-.183a1 1 0 0 0 0-1.898l-.551-.184a1 1 0 0 1-.633-.632l-.183-.551Z" />
        </svg>
        <span class="font-base text-sm">Pro User</span>
    </button>
</div>