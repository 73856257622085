import { Injectable } from '@angular/core';
import { SupabaseService } from '../supabase/supabase.service';
import { Observable, from, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private supabase: SupabaseService) { }

  public createUpgradeLink(): Observable<{ url: string }> {
    const userId = this.supabase.userId;
    return from(this.supabase.supabase.functions.invoke('create-checkout-session', {
      method: 'POST',
      body: JSON.stringify({ userId })
    })).pipe(map(response => response.data as { url: string }));
  }

  public openCustomerPortal(): Observable<{ url: string }> {
    const userId = this.supabase.userId;
    return from(this.supabase.supabase.functions.invoke('open-customer-portal', {
      method: 'POST',
      body: JSON.stringify({ userId })
    })).pipe(map(response => response.data as { url: string }));
  }
}
