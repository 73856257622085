import { ComponentPortal, ComponentType, Portal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, InjectionToken, Injector, ViewContainerRef } from '@angular/core';
import { Subject, delay, take, takeUntil } from 'rxjs';
import { AbstractModalComponent, ModalRef } from '../../modal-ref';
import { ModalService } from '../../services/modal/modal.service';

export const MODAL_DATA = new InjectionToken<string>("MODAL_DATA");

@Component({
  selector: 'modal-outlet',
  standalone: true,
  imports: [
    CommonModule,
    PortalModule
  ],
  templateUrl: './modal-outlet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalOutletComponent {

  private _destroyed$: Subject<boolean> = new Subject<boolean>();

  public activePortal: Portal<any> | undefined;
  public portal: ComponentPortal<AbstractModalComponent> | undefined;


  constructor(public modalService: ModalService, private _viewContainerRef: ViewContainerRef, private cdr: ChangeDetectorRef) {

  }

  ngAfterViewInit(): void {
    this._subscribeToSlideOverEvents();
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  private _subscribeToSlideOverEvents(): void {
    this.modalService.onModalOpen$.pipe(takeUntil(this._destroyed$)).subscribe((event) => {
      this.portal = new ComponentPortal<AbstractModalComponent>(
        event.content as ComponentType<AbstractModalComponent>,
        this._viewContainerRef,
        Injector.create({
          providers: [{ provide: MODAL_DATA, useValue: event.options.data }],
        }),
      );

      this.activePortal = this.portal;
      this.modalService.modalRef = event;
      this.cdr.detectChanges();

      setTimeout(() => {
        const activeSlideOverRef: ModalRef<unknown> | undefined = this.modalService.modalRef;
        if (!activeSlideOverRef) return;
        activeSlideOverRef.open();
        this._subscribeToCloseSlideOverEvents(activeSlideOverRef);
      });
    })
  }

  private _subscribeToCloseSlideOverEvents(slideOverRef: ModalRef<unknown>): void {
    slideOverRef.onClose$.pipe(take(1), delay(75)).subscribe(() => {
      const activePortal: Portal<any> | undefined = this.activePortal;
      if (!activePortal) return;
      activePortal.detach();
      this.portal = undefined;
      this.modalService.modalRef = undefined;
      this.cdr.detectChanges();
    })
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.modalService.modalRef) {
      return;
    }

    const target = event.target as Node;
    if (!this.modalService.modalRef.nativeElement?.contains(target)) {
      this.modalService.close();
    }
  }

}
