import { Injectable, isDevMode } from '@angular/core';
import OpenReplay from '@openreplay/tracker'
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

type ReqRespType = {
  request: HttpRequest<any>,
  response: HttpResponse<any>
}

@Injectable({
  providedIn: 'root'
})
export class ReplaySessionService {

  tracker: OpenReplay | null = null

  constructor() {
    if (!isDevMode()) {
      this.tracker = new OpenReplay({
        projectKey: environment.openReplayKey,
      })

      this.tracker.start()
    }

  }

  sendEventToReplaySession(event: string, params:  ReqRespType): void {
    const { request, response } = params

    this.tracker?.event(event + "[request]", {
      method: request.method,
      url: request.url,
      params: request.params
    })
    this.tracker?.event(event + "[response]", {
      body: response.body,
      status: response.status,
      headers: response.headers
    })
  }
}
