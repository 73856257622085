import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Session, SessionGroup, SessionService } from '../../../../services/session/session.service';
import { Subject, takeUntil } from 'rxjs';
import { TippyDirective } from '@ngneat/helipopper';
import { RouterModule, UrlMatchResult } from '@angular/router';
import { ModalService } from '../../../../services/modal/modal.service';
import { UpgradeModalComponent } from '../../../../modals/upgrade-modal/upgrade-modal.component';
import { User } from '@supabase/supabase-js';
import { StripeService } from '../../../../services/stripe/stripe.service';

@Component({
  selector: 'sidebar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TippyDirective,
  ],
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent {

  @Input() sessionGroups: SessionGroup[] | undefined;
  @Input() user: User | null = null;
  @Output() public toggleSidebar: EventEmitter<void> = new EventEmitter<void>();
  @Output() public createNewSession: EventEmitter<void> = new EventEmitter<void>();

  constructor(private modalService: ModalService, private stripeService: StripeService) {

  }

  public trackSession(index: number, session: Session): string {
    return session.id;
  }

  public openUpgradeModal(): void {
    this.modalService.open(UpgradeModalComponent)
  }

  public openCustomerPortal(): void {
    if (!this.user || this.user.is_anonymous) {
      return;
    }

    this.stripeService.openCustomerPortal().subscribe(response => {
      window.location.assign(response.url);
    })
  }

}
